import { useContext } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from '../context/GlobalContext';
import { DEFAULT_CALLBACK_URL_SIGNED_OFF } from 'src/misc/Config';
import queryString from 'query-string';
import { objToQueryStringParams } from 'src/misc/Misc';

const PrivateRoute = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  if (globalContext.userInfo) return children;
  const { forceEnv } = queryString.parse(window.location.search);
  window.location.href = `${DEFAULT_CALLBACK_URL_SIGNED_OFF}${objToQueryStringParams({ forceEnv })}`;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
