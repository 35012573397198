import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const PropertySection = ({ children, color, bgColor, roundedSize = 'xl', px = 3, py = 1 }) => {
  const { theme } = useTheme();
  return (
    <div className={`rounded-${roundedSize} px-${px} py-${py} `} style={{ border: `1px solid ${color ?? theme.borderLight}`, backgroundColor: bgColor }}>
      {children}
    </div>
  );
};

PropertySection.propTypes = {
  children: PropTypes.any,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  roundedSize: PropTypes.string,
  px: PropTypes.number,
  py: PropTypes.number,
};

export default PropertySection;
