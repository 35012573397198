import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FsiReportContext from 'src/context/FsiReportContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import { useParams } from 'react-router-dom';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import { Space, Spin, message, Checkbox, Popconfirm, Button } from 'antd';
import { TbReportMoney } from 'react-icons/tb';
import { getProject, postProjectQuestions, deleteProject } from 'src/api/fsireport';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import UniqueRegions from './Components/UniqueRegions';
import LanguagesWidget from './Components/LanguagesWidget';
import QuestionsWidget from './Components/QuestionsWidget';
import ReportsWidget from './Components/ReportsWidget';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import ReportsWidgetBtns from './Components/ReportsWidgetBtns';

import StyledButton from 'src/components/layout/StyledButton';
import { FaPlus } from 'react-icons/fa6';
import useTheme from 'src/hooks/useTheme';
import QuestionAddModal from './Components/QuestionAddModal';
import CustomHeaders from './Components/CustomHeadersWidget';
import { uuid } from 'short-uuid';
import { FaRegTrashCan } from 'react-icons/fa6';
import useUserInfo from 'src/hooks/useUserInfo';
import { hasUserPermission } from 'src/misc/Session';

//import PropTypes from 'prop-types';

const ProjectPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { projectId } = useParams();
  const { currentProject, setCurrentProject, resetCurrentProject, setExeSummaries } = useContext(FsiReportContext);
  const navigate = useNavigate();

  const userInfo = useUserInfo();

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);
  const [addQuestionModalVisible, setAddQuestionModalVisible] = useState(false);
  const [deleteReports, setDeleteReports] = useState(false);

  const handleCheckboxChange = (e) => {
    setDeleteReports(e.target.checked);
  };

  const content = (
    <div>
      <Checkbox onChange={handleCheckboxChange}>Delete all corresponding Reports</Checkbox>
    </div>
  );

  useEffect(() => {
    if (!currentProject) loadData();

    setBreadcrumb([
      {
        title: <>FSI Report</>,
        path: '/fsi-report',
      },
      {
        title: <>Project</>,
      },
    ]);

    return () => {
      resetCurrentProject();
      dropBreadcrumb();
    };
  }, []);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      try {
        const project = await getProject(projectId);
        console.log('project:', project);
        setCurrentProject(project);
        setExeSummaries(project?.props?.exeSummaries ?? null);
      } catch (error) {
        console.error(error);
        if (error?.toString()?.includes('404')) {
          navigate('/404');
        }
      }
    });
  };

  const handleQuestionReorder = (newQuestions) => {
    setCurrentProject({
      ...currentProject,
      questions: newQuestions,
    });
  };

  const componentsGallery = [
    {
      id: 'Questions',
      col: 0,
      component: (
        <DraggableLayoutItem title='Questions' centered>
          <QuestionsWidget onReorder={handleQuestionReorder} />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Reports',
      col: 1,
      component: (
        <DraggableLayoutItem title='Reports' titleExtras={<ReportsWidgetBtns />}>
          <ReportsWidget onReorder={handleQuestionReorder} />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Regions',
      col: 1,
      component: (
        <DraggableLayoutItem title='Regions' centered>
          <UniqueRegions onReorder={handleQuestionReorder} />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Languages',
      col: 1,
      component: (
        <DraggableLayoutItem title='Languages' centered>
          <LanguagesWidget onReorder={handleQuestionReorder} />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'Custom Headers',
      col: 1,
      component: (
        <DraggableLayoutItem title='Custom Headers' centered>
          <CustomHeaders onReorder={handleQuestionReorder} />
        </DraggableLayoutItem>
      ),
    },
  ];

  const handleAddQuestionOnClick = () => {
    setAddQuestionModalVisible(true);
  };

  const handleAddQuestionOnCancel = () => {
    setAddQuestionModalVisible(false);
  };

  const handleDeleteProject = async () => {
    console.log('handleDeleteProject');

    await executeAsyncProcess(async () => {
      try {
        messageApi.loading('Deleting project...');
        await deleteProject(projectId, deleteReports);
        messageApi.success('Project deleted');
        navigate('/fsi-report');
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleAddQuestionOnSubmit = async (selectedQuestions) => {
    console.log('selectedQuestions:', selectedQuestions);
    setAddQuestionModalVisible(false);
    const key = uuid();
    await executeAsyncProcess(async () => {
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Adding questions...',
          duration: 0,
        });

        await postProjectQuestions(projectId, selectedQuestions);
        messageApi.open({
          key,
          type: 'success',
          content: 'Questions added',
          duration: 3,
        });

        loadData();
      } catch (error) {
        console.error(error);
      }
    });
  };

  if (!currentProject) return <FullScreenSpin title='Please wait' subtitle='Loading project' />;

  return (
    <PermissionsProvider requiredPermissions={['apps.fsireport.view']}>
      {contextHolder}
      {addQuestionModalVisible && <QuestionAddModal onClose={handleAddQuestionOnCancel} onSubmit={handleAddQuestionOnSubmit} />}
      <TitleBar
        title={
          <Space className='btn'>
            <TbReportMoney />
            FSI Report
          </Space>
        }
        afterTitleExtras={
          <Space className='btn'>
            <PageSwitch pageList={pageList} label={currentProject?.accName ?? <Spin size='small' />} />
            <StyledButton color={theme.textBase} onClick={handleAddQuestionOnClick}>
              <Space className='btn'>
                <FaPlus />
                Add questions
              </Space>
            </StyledButton>
          </Space>
        }>
        {hasUserPermission('apps.fsireport.delete', userInfo.permissions) && (
          <Popconfirm title='Delete this Project?' onConfirm={handleDeleteProject} okText='Yes' cancelText='No' description={content}>
            <Button type='text' style={{ color: theme.textLight, padding: '2px 0', height: '26px', width: '26px' }} className='hover:scale-125'>
              <FaRegTrashCan />
            </Button>
          </Popconfirm>
        )}

        {layoutItemsSelector}
      </TitleBar>
      <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={0} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutFsiProject} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutFsiProjectHidden} />
    </PermissionsProvider>
  );
};

ProjectPage.propTypes = {};

export default ProjectPage;
