import React, { useEffect, useState, useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';
import FieldString from './FieldString';
import FieldNumber from './FieldNumber';
import FieldBoolean from './FieldBoolean';
import FieldStringArray from './FieldStringArray';
import FieldCustomObject from './FieldCustomObject';

var teacketServicePropValues1 = null;
const TeacketServiceEditorConfigForm = ({ propName, propDescription, onOk, onCancel }) => {
  const { teacketServicePropList, teacketServicePropShowModal } = useContext(MasterAdminContext);
  const [currentPropList, setCurrentPropList] = useState(null);
  const [fieldList, setFieldList] = useState(null);

  const [teacketServicePropValues, setTeacketServicePropValues] = useState(null);

  useEffect(() => {
    teacketServicePropValues1 = teacketServicePropValues;
  }, [teacketServicePropValues]);

  useEffect(() => {
    if (!teacketServicePropList) return;
    const pl = teacketServicePropList.find((p) => p.title === propName);
    if (!pl) return;
    setCurrentPropList(pl);
    setTeacketServicePropValues({ ...teacketServicePropShowModal?.record });
  }, []);

  useEffect(() => {
    if (!currentPropList) return;
    createFields();
  }, [currentPropList]);

  const handleOnChange = (e) => {
    //console.log('handleOnChange()', e, teacketServicePropValues1);
    const pv = { ...teacketServicePropValues1 };
    pv[e.propName] = JSON.parse(JSON.stringify(e.value)); // Deep copy if necessary
    setTeacketServicePropValues(pv);
  };

  const handleOnOk = () => {
    console.log('handleOnOk()');
    if (!onOk) return;
    onOk({ item: teacketServicePropValues });
  };

  const createFields = () => {
    if (!currentPropList?.items?.properties) return null;
    const fieldList = [];
    const { properties } = currentPropList.items;

    for (const p in properties) {
      if (properties[p]?.customObject) {
        fieldList.push(
          <div key={p}>
            <FieldCustomObject defaultValue={teacketServicePropValues[p]} propName={p} fieldDef={properties[p]} onChange={handleOnChange} />
          </div>
        );
      } else {
        switch (properties[p]?.type) {
          case 'string':
            fieldList.push(
              <div key={p}>
                <FieldString defaultValue={teacketServicePropValues[p]} propName={p} fieldDef={properties[p]} onChange={handleOnChange} />
              </div>
            );
            break;
          case 'number':
            fieldList.push(
              <div key={p}>
                <FieldNumber defaultValue={teacketServicePropValues[p]} propName={p} fieldDef={properties[p]} onChange={handleOnChange} />
              </div>
            );
            break;
          case 'boolean':
            fieldList.push(
              <span key={p}>
                <FieldBoolean defaultValue={teacketServicePropValues[p]} propName={p} fieldDef={properties[p]} onChange={handleOnChange} />
              </span>
            );
            break;
          case 'array':
            fieldList.push(
              <div key={p}>
                <FieldStringArray defaultValue={teacketServicePropValues[p]} propName={p} fieldDef={properties[p]} onChange={handleOnChange} />
              </div>
            );
            break;
          default:
            console.error('Unknown type:', properties[p].type);
        }
      }
    }
    setFieldList(fieldList);
  };

  if (!fieldList) return null;
  if (!teacketServicePropValues) return null;

  return (
    <Modal
      width={1600}
      title={
        <>
          {propDescription} - {teacketServicePropShowModal.mode}
        </>
      }
      open={true}
      footer={
        <Button type='primary' onClick={handleOnOk}>
          Save
        </Button>
      }
      onCancel={() => {
        if (!onCancel) return;
        onCancel();
      }}
    >
      <div className='flex flex-col gap-4'>{fieldList}</div>
    </Modal>
  );
};

TeacketServiceEditorConfigForm.propTypes = {
  propName: PropTypes.string.isRequired,
  propDescription: PropTypes.string.isRequired,
  initialData: PropTypes.object,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TeacketServiceEditorConfigForm;
