import { objToQueryStringParams } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

var controller;

export const getAccountsByName = async (name, limit, onlyWithOrganization = false) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts?name=${name}&limit=${limit || 50}&onlyWithOrganization=${onlyWithOrganization}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getOpportunititesByName = async (name, active, limit) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunities?name=${name}&active=${active || false}&limit=${limit || 50}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getOpportunityById = async (id) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunities/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getOpportunititesForAccount = async (id, active) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts/${id}/opportunities?active=${active || false}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getOpportunityDSR = async (id) => {
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunities/${id}/dsr`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getAccOrgs = async (accId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts/${accId}/organizations`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAccountForOrgId = async (deployments) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/organization/account${objToQueryStringParams({ deployments })}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getAccountInformation = async (acc_id) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts/${acc_id}/information`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getAccountDeployments = async (acc_id) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accounts/${acc_id}/deployments`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getOrgProductLine = async (orgId) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/organizations/${orgId}/product-line`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );

export const getDeployments = async (query, limit = 25, type, search) => {
  controller?.abort('cancelled');
  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/deployments${objToQueryStringParams({ query, limit, type, search })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getTopOpportunities = async (startDate, endDate, regionFilter, subRegionFilter, quarterFilter, pevMinAmountFilter, platformFilter, forecastFilter, stageFilter, typeFilter) => {
  console.log('getTopOpportunities', startDate, endDate, regionFilter, subRegionFilter, quarterFilter, pevMinAmountFilter, platformFilter, forecastFilter, stageFilter, typeFilter);

  controller?.abort('cancelled');
  controller = new AbortController();
  const signal = controller.signal;

  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunities/top${objToQueryStringParams({ startDate, endDate, regionFilter, subRegionFilter, quarterFilter, pevMinAmountFilter, platformFilter, forecastFilter, stageFilter, typeFilter })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      signal,
    }),
    true,
    false,
    true,
    false
  );
};

export const getDistinctRegions = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/regions`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctPlatforms = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/platforms`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctQuarters = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/quarters`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctForecasts = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/forecasts`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctStages = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/stages`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctAccountGroupings = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accountG`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctAccounts = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/accountNames`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctOpportunities = async (accountIds) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunityNames?accountIds=${accountIds.join(',')}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctTypes = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/types`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctMarketSegments = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/marketSegments`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctDirectIndirects = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/directIndirects`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getDistinctCustomerTypes = async () => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/customerTypes`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getOpportunityJourney = async (oppId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/opportunity/journey?oppId=${oppId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const getAccountJourney = async (accId) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/snowflake/account/journey?accId=${accId}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};
