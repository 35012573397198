import React, { useContext, useEffect } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import StakeholderDetails from './StakeholderDetails';
import { patchTicket, patchTicketProperties } from 'src/api/teacket';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';

const Stakeholder = () => {
  const { setCurrentTicketSharepointFilesOppAccUpdated, currentTicket, setCurrentTicket } = useContext(TeacketContext);


  useEffect(() => {
    if (currentTicket) {
      console.log('[Stakeholder] Current ticket', currentTicket);
    }

  }, [currentTicket]);

  const handleTicketChange = async (changes) => {
    console.log('[Stakeholder] Ticket changes', JSON.stringify(changes, null, 2));

    const { acc_id, acc_name, opp_id, opp_name } = changes;

    if (changes.props) {
      const processedProps = { ...changes.props };

      // Special handling for region
      if ('OPPORTUNITY_COUNTRY_REGION' in processedProps) {
        processedProps.region = processedProps.OPPORTUNITY_COUNTRY_REGION.toUpperCase();
      } else if ('ACCOUNT_REGION' in processedProps) {
        processedProps.region = processedProps.ACCOUNT_REGION.toUpperCase();
      }

      // Special handling for sales segment
      if ('OPPORTUNITY_SALES_SEGMENT' in processedProps) {
        processedProps.sales_segment = processedProps.OPPORTUNITY_SALES_SEGMENT;
      } else if ('ACCOUNT_SALES_SEGMENT' in processedProps) {
        processedProps.sales_segment = processedProps.ACCOUNT_SALES_SEGMENT;
      }

      // Define mapping of fields to their simplified names
      const propertyMapping = {
        'ACCOUNT_GROUPING': 'account_grouping',
        'ORG_COUNT': 'org_count',
        'OPPORTUNITY_COUNTRY_SUB_REGION': 'sub_region',
        'OPPORTUNITY_DIRECT_INDIRECT_SALE': 'direct_indirect_sale',
        'OPPORTUNITY_DEAL_TYPE': 'deal_type',
        'OPPORTUNITY_PRODUCT': 'product',
        'OPPORTUNITY_STAGE_NAME': 'stage_name',
        'OPPORTUNITY_GROSS_ACV_BOOKING': 'gross_acv_booking',
        'OPPORTUNITY_GROSS_ACV_BOOKING_USD': 'gross_acv_booking_usd',
        'OPPORTUNITY_AGENTS': 'agents',
        'OPPORTUNITY_CLOSE_DATE': 'close_date',
        'OPPORTUNITY_CREATED_DATE': 'created_date',
        'OPPORTUNITY_CURRENCY_ISO_CODE': 'currency_iso_code',
        'OPPORTUNITY_DSR_URL': 'dsr_url',
        'OPPORTUNITY_SEATS': 'seats',
        'OPPORTUNITY_OWNER_NAME': 'owner_name',
        'OPPORTUNITY_OWNER_EMAIL': 'owner_email',
        'OPPORTUNITY_SC_ID': 'sc_id',
        'OPPORTUNITY_SC_NAME': 'sc_name',
        'OPPORTUNITY_SC_EMAIL': 'sc_email',
      };

      // Process mapped fields
      Object.entries(propertyMapping).forEach(([sourceKey, targetKey]) => {
        processedProps[targetKey] = sourceKey in processedProps
          ? processedProps[sourceKey]
          : null;
      });

      // Remove all ACCOUNT_* and OPPORTUNITY_* properties
      Object.keys(processedProps).forEach(key => {
        if (key.startsWith('ACCOUNT_') || key.startsWith('OPPORTUNITY_') || key === 'ORG_COUNT') {
          delete processedProps[key];
        }
      });

      changes.props = processedProps;
    }

    //#endregion

    await patchTicket(currentTicket.id, changes);

    //#region "trigger moveFiles()"

    const old1 = { acc_id: currentTicket?.acc_id, acc_name: currentTicket?.acc_name, opp_id: currentTicket?.opp_id, opp_name: currentTicket?.opp_name };
    const new1 = { acc_id, acc_name, opp_id, opp_name };
    setCurrentTicketSharepointFilesOppAccUpdated({ old1, new1 });

    //#endregion

    console.log('Setting current ticket to', JSON.stringify({ ...currentTicket, ...changes }, null, 2));
    setCurrentTicket({ ...currentTicket, ...changes });
  };


  const handleTicketPropertiesChange = async (properties) => {
    console.log('[Stakeholder] Updated properties', JSON.stringify(properties, null, 2));

    if (currentTicket) {
      await patchTicketProperties(currentTicket.id, properties);
      // console.log('=== Current ticket props:', JSON.stringify(currentTicket.props, null, 2));
      // console.log('=== With merged properties', JSON.stringify({ ...currentTicket, props: { ...currentTicket.props, ...properties } }, null, 2));
      setCurrentTicket({ ...currentTicket, props: { ...currentTicket.props, ...properties } });
    }
  };

  const handleOnWsMessageReceived = async (message) => {
    try {
      console.log('[Stakeholder] Message', message);
      if (!message?.data?.details) return;

      //handleTicketPropertiesChange(message.data.details);
      if (currentTicket) {
        setCurrentTicket({ ...currentTicket, props: { ...currentTicket.props, ...message?.data?.details } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_UPDATED_PROPS']} />
      <StakeholderDetails ticket={currentTicket} onTicketChange={handleTicketChange} onPropertiesChange={handleTicketPropertiesChange} />
    </>
  );
};

export default Stakeholder;
