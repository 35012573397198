import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
//import PropTypes from 'prop-types';

const CondensedTag1 = styled.div`
  display: flex;
  .ant-tag {
    padding: 0px 4px !important;
    margin: 0 !important;
    zoom: 0.8;
    font-weight: 800;
    line-height: 1rem;
  }
`;

const CondensedTabs = (props) => {
  return (
    <CondensedTag1>
      <Tag {...props} />
    </CondensedTag1>
  );
};

//CondensedTabs.propTypes = {};

export default CondensedTabs;
