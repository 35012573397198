import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import useTheme from 'src/hooks/useTheme';
import TitleBar from 'src/components/layout/TitleBar';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import { message, Space, Table, Tag, Tooltip, Button } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import { pageList } from '../Misc/misc';
import PageSwitch from 'src/components/layout/PageSwitch';
import TicketListControl from '../TicketList/components/TicketListControl';
import DashboardFilters from '../Dashboard/components/DashboardFilters/DashboardFilters';
import dayjs from 'dayjs';
import { getBrowserTimeZone } from 'src/misc/Timezone';
import { getTopOpportunities } from 'src/api/snowflake';
import { getTicketCounts } from 'src/api/teacket';
import useExport from 'src/hooks/useExport';
import { FaDownload } from 'react-icons/fa';
import BarLoader from 'react-bar-loader';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .ant-table {
    margin-block: 0 !important;
    margin-inline: 0 !important;
  }
`;

const messageKey = 'counter';

const AdminDashboard = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { exportToCSV } = useExport();
  const { theme } = useTheme();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: 'small',
    position: ['topRight', 'bottomRight'],
  });

  const [messageApi, contextHolder] = message.useMessage();

  const { dashboardStartDate, setDashboardStartDate, dashboardEndDate, setDashboardEndDate, dashboardRegions, dashboardSubRegions, dashboardQuarters, dashboardACVs, dashboardPlatforms, dashboardForecasts, dashboardStages, dashboardTypes, dashboardRequestTypes, dashboardRequestStatuses, dashboardRequestCategories, dashboardRequestSubCategories } = useContext(TeacketContext);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Dashboards',
      },
      {
        title: 'Admin Dashboard',
      },
    ]);

    setDefaultRange();

    return () => {
      dropBreadcrumb();
    };
  }, []);

  useEffect(() => {
    if (initiated) return;
    if (!dashboardStartDate) return;
    if (!dashboardEndDate) return;

    setInitiated(true);
  }, [dashboardStartDate, dashboardEndDate]);

  const setDefaultRange = () => {
    const timezone = getBrowserTimeZone();
    const start = dayjs().tz(timezone).subtract(7, 'days').startOf('day');
    const end = dayjs().tz(timezone).subtract(1, 'days').endOf('day');
    setDashboardStartDate(start);
    setDashboardEndDate(end);
  };

  const loadData = async () => {
    console.log('Loading data...');

    let hideLoadingSnowflakeMessage, hideCountMessage;
    try {
      setLoading(true);
      setData([]);

      let currentData = [];

      //#region Get top opportunities from Snowflake

      hideLoadingSnowflakeMessage = messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Getting opportunities from Snowflake...',
        duration: 0,
      });

      const currentTopOpportunities = await getTopOpportunities(dayjs(dashboardStartDate).format(), dayjs(dashboardEndDate).format(), dashboardRegions, dashboardSubRegions, dashboardQuarters, dashboardACVs, dashboardPlatforms, dashboardForecasts, dashboardStages, dashboardTypes);
      console.log('currentTopOpportunities:', currentTopOpportunities);

      currentData = currentTopOpportunities;
      if (hideLoadingSnowflakeMessage) hideLoadingSnowflakeMessage(); // Close the loading message

      //#endregion

      //#region Get ticket counts for each account & opportunity

      hideCountMessage = messageApi.open({
        key: messageKey,
        type: 'loading',
        content: 'Counting tickets...',
        duration: 0,
      });

      const sfData = currentData.map((item) => {
        return {
          accountId: item['Acc 18 Digit ID'],
          opportunityId: item['18 Digit ID'],
        };
      });

      const ticketCounts = await getAllTicketCounts(sfData);
      console.log('ticketCounts:', ticketCounts);

      const dataWithKeysAndCounts = currentData.map((item, index) => {
        if (item['Acc 18 Digit ID'] && item['18 Digit ID']) {
          // Both account and opportunity are set
          const ticketCount = ticketCounts.find((count) => count.acc_id === item['Acc 18 Digit ID'] && count.opp_id === item['18 Digit ID']);
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: ticketCount ? ticketCount.count : 0, // Add ticket count to the item
          };
        } else if (item['Acc 18 Digit ID']) {
          // Only account is set
          const ticketCount = ticketCounts.find((count) => count.acc_id === item['Acc 18 Digit ID']);
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: ticketCount ? ticketCount.count : 0, // Add ticket count to the item
          };
        } else {
          // Neither account nor opportunity is set
          return {
            ...item,
            key: `${item.member}-${index}`,
            ticketCounts: 0, // Add ticket count to the item
          };
        }
      });

      //#endregion

      setData(dataWithKeysAndCounts);
      message.success('All opportunities loaded!'); // Show success message
    } catch (error) {
      console.error(error);
      if (error.message.indexOf('404') !== -1) {
        message.warning('No opportunities found');
      } else {
        message.error('Failed to load data');
      }
    } finally {
      if (hideLoadingSnowflakeMessage) hideLoadingSnowflakeMessage(); // Close the loading message
      if (hideCountMessage) hideCountMessage(); // Close the loading message
      setLoading(false);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const getAllTicketCounts = async (sfData) => {
    try {
      const chunkSize = 100; // Adjust the chunk size as needed to avoid hitting the API return data size limit
      const ticketCounts = [];

      const sfDataChunks = chunkArray(sfData, chunkSize);
      console.log('sfDataChunks:', sfDataChunks);

      let index = 0;
      for (const chunk of sfDataChunks) {
        index++;
        messageApi.open({
          key: messageKey,
          type: 'loading',
          content: `Counting tickets (${sfData.length < chunkSize ? sfData.length : sfData.length < chunkSize * index ? sfData.length : chunkSize * index}/${sfData.length})...`,
          duration: 0,
        });

        const chunkTicketCounts = await getTicketCounts(chunk, dashboardRequestTypes, dashboardRequestStatuses, dashboardRequestCategories, dashboardRequestSubCategories);
        ticketCounts.push(...chunkTicketCounts);
      }

      console.log('ticketCounts:', ticketCounts);
      return ticketCounts;
    } catch (error) {
      console.error('Failed to get ticket counts for accounts:', sfData, error);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    setPagination(pagination);
  };

  const formatCurrency = (value, currency) => {
    if (!value || !currency) return value;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(value);
  };

  const sortByQuarterAndYear = (a, b) => {
    const [quarterA, yearA] = a['Closing Fiscal Quarter'].split('-');
    const [quarterB, yearB] = b['Closing Fiscal Quarter'].split('-');

    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }

    return quarterA.localeCompare(quarterB);
  };

  const truncateText = (text, maxLength) => {
    if (!text) return text;
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: 'Tickets',
      dataIndex: 'ticketCounts',
      key: 'ticketCounts',
      fixed: 'center',
      sorter: (a, b) => {
        return a.ticketCounts - b.ticketCounts;
      },
      sortOrder: sortedInfo.columnKey === 'ticketCounts' && sortedInfo.order,
      render: (text) => <Tag className='px-3 py-1.5 rounded-full font-medium border-0 bg-genesys-azure-100 text-genesys-azure-800 dark:bg-genesys-azure-900 dark:text-genesys-azure-100'>{text}</Tag>,
    },
    {
      title: 'Account Info',
      children: [
        {
          title: 'Account',
          dataIndex: 'Account Name',
          key: 'Account Name',
          fixed: 'center',
          sorter: (a, b) => a['Account Name']?.localeCompare(b['Account Name']),
          sortOrder: sortedInfo.columnKey === 'Account Name' && sortedInfo.order,
          render: (text, record) => (
            <a href={`https://genesys.lightning.force.com/lightning/r/Account/${record['Acc 18 Digit ID']}/view`} target='_blank' rel='noopener noreferrer'>
              {text}
            </a>
          ),
        },
        {
          title: 'Opportunity',
          dataIndex: 'Opportunity Name',
          key: 'Opportunity Name',
          fixed: 'center',
          sorter: (a, b) => a['Opportunity Name']?.localeCompare(b['Opportunity Name']),
          sortOrder: sortedInfo.columnKey === 'Opportunity Name' && sortedInfo.order,
          render: (text, record) => (
            <Tooltip title={text}>
              <a href={`https://genesys.lightning.force.com/lightning/r/Opportunity/${record['18 Digit ID']}/view`} target='_blank' rel='noopener noreferrer'>
                {truncateText(text, 15)}
              </a>
            </Tooltip>
          ),
        },
      ],
    },
    {
      title: 'ACV',
      children: [
        {
          title: 'Local',
          dataIndex: 'Value',
          key: 'Value',
          fixed: 'center',
          sorter: (a, b) => a.Value - b.Value,
          sortOrder: sortedInfo.columnKey === 'Value' && sortedInfo.order,
          render: (text, record) => <div className='float-right'>{formatCurrency(text || 0, record.Currency)}</div>,
        },
        {
          title: 'Currency',
          hidden: true,
          dataIndex: 'Currency',
          key: 'Currency',
          fixed: 'center',
          sorter: (a, b) => a.Currency?.localeCompare(b.Currency),
          sortOrder: sortedInfo.columnKey === 'Currency' && sortedInfo.order,
        },
        {
          title: 'USD ($)',
          dataIndex: 'ValueUSD',
          key: 'ValueUSD',
          fixed: 'center',
          sorter: (a, b) => a.ValueUSD - b.ValueUSD,
          sortOrder: sortedInfo.columnKey === 'ValueUSD' && sortedInfo.order,
          render: (text) => <div className='float-right'>{formatCurrency(text || 0, 'USD')}</div>,
        },
      ],
    },
    {
      title: 'Closing Q.',
      dataIndex: 'Closing Fiscal Quarter',
      key: 'Closing Fiscal Quarter',
      fixed: 'center',
      sorter: sortByQuarterAndYear,
      sortOrder: sortedInfo.columnKey === 'Closing Fiscal Quarter' && sortedInfo.order,
    },
    {
      title: 'Forecast',
      dataIndex: 'Forecast Status',
      key: 'Forecast Status',
      fixed: 'center',
      sorter: (a, b) => a['Forecast Status']?.localeCompare(b['Forecast Status']),
      sortOrder: sortedInfo.columnKey === 'Forecast Status' && sortedInfo.order,
    },
    {
      title: 'Stage',
      dataIndex: 'Stage',
      key: 'Stage',
      fixed: 'center',
      sorter: (a, b) => a.Stage?.localeCompare(b.Stage),
      sortOrder: sortedInfo.columnKey === 'Stage' && sortedInfo.order,
    },
    {
      title: 'Agents',
      dataIndex: 'Agents',
      key: 'Agents',
      fixed: 'center',
      sorter: (a, b) => a.Agents?.localeCompare(b.Agents),
      sortOrder: sortedInfo.columnKey === 'Agents' && sortedInfo.order,
    },
    {
      title: 'Owner',
      dataIndex: 'Opportunity Owner',
      key: 'Opportunity Owner',
      fixed: 'center',
      sorter: (a, b) => a['Opportunity Owner']?.localeCompare(b['Opportunity Owner']),
      sortOrder: sortedInfo.columnKey === 'Opportunity Owner' && sortedInfo.order,
    },
    {
      title: 'Partner',
      dataIndex: 'Partner Name',
      key: 'Partner Name',
      fixed: 'center',
      sorter: (a, b) => a['Partner Name']?.localeCompare(b['Partner Name']),
      sortOrder: sortedInfo.columnKey === 'Partner Name' && sortedInfo.order,
      render: (text) => (
        <Tooltip title={text}>
          <div>{truncateText(text, 15)}</div>
        </Tooltip>
      ),
    },
    {
      title: 'Offering',
      dataIndex: 'Offering Detail',
      key: 'Offering Details',
      fixed: 'center',
      sorter: (a, b) => a['Offering Detail']?.localeCompare(b['Offering Detail']),
      sortOrder: sortedInfo.columnKey === 'Offering Detail' && sortedInfo.order,
    },
    {
      title: 'DSR',
      dataIndex: 'SC DSR Name',
      key: 'SC DSR Name',
      fixed: 'center',
      sorter: (a, b) => a['SC DSR Name']?.localeCompare(b['SC DSR Name']),
      sortOrder: sortedInfo.columnKey === 'SC DSR Name' && sortedInfo.order,
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
      fixed: 'center',
      sorter: (a, b) => a.Type?.localeCompare(b.Type),
      sortOrder: sortedInfo.columnKey === 'Type' && sortedInfo.order,
    },
    {
      title: 'Lead Offer',
      dataIndex: 'Lead Offer',
      key: 'Lead Offer',
      fixed: 'center',
      sorter: (a, b) => a['Lead Offer']?.localeCompare(b['Lead Offer']),
      sortOrder: sortedInfo.columnKey === 'Lead Offer' && sortedInfo.order,
    },
    {
      title: 'Outgoing Platform',
      dataIndex: 'Outgoing Platform Migration',
      key: 'Outgoing Platform Migration',
      fixed: 'center',
      sorter: (a, b) => a['Outgoing Platform Migration']?.localeCompare(b['Outgoing Platform Migration']),
      sortOrder: sortedInfo.columnKey === 'Outgoing Platform Migration' && sortedInfo.order,
    },
    {
      title: 'Region',
      dataIndex: 'Owner Region',
      key: 'Owner Region',
      fixed: 'center',
      sorter: (a, b) => a['Owner Region']?.localeCompare(b['Owner Region']),
      sortOrder: sortedInfo.columnKey === 'Owner Region' && sortedInfo.order,
    },
    {
      title: 'Sub Region',
      dataIndex: 'Owner Sub Region',
      key: 'Owner Sub Region',
      fixed: 'center',
      sorter: (a, b) => a['Owner Sub Region']?.localeCompare(b['Owner Sub Region']),
      sortOrder: sortedInfo.columnKey === 'Owner Sub Region' && sortedInfo.order,
    },
    {
      title: 'Renewal',
      dataIndex: 'Renewal',
      key: 'Renewal',
      fixed: 'center',
      sorter: (a, b) => a.Renewal?.localeCompare(b.Renewal),
      sortOrder: sortedInfo.columnKey === 'Renewal' && sortedInfo.order,
    },
  ];

  const expandableConfig = {
    expandRowByClick: true,
    expandedRowKeys: expandedKeys,
    expandedRowRender: (record) => {
      if (!record['Account ID']) return null;

      return <TicketListControl accountId={record['Acc 18 Digit ID']} opportunityId={record['18 Digit ID']} />;
    },
    onExpand: async (expanded, record) => {
      setExpandedKeys((prevKeys) => {
        if (expanded) {
          return [...prevKeys, record.key];
        }
        return prevKeys.filter((key) => key !== record.key);
      });
    },
    rowExpandable: (record) => record.ticketCounts > 0,
  };

  return (
    <StyledDiv>
      {contextHolder}
      <PermissionsProvider requiredPermissions={['apps.teacket.dashboards.admindashboard.view']}>
        <LoaderServicesForTenant>
          <TitleBar
            title={
              <Space className='btn'>
                <BsCupHotFill />
                <div>
                  <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
                </div>
              </Space>
            }
            afterTitleExtras={
              <div className='flex flex-row items-center gap-4'>
                <div>
                  <PageSwitch pageList={pageList} />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      exportToCSV({ columns, data, fileName: 'GreenTeacketAdminDashboard.csv' });
                    }}
                  >
                    <FaDownload />
                  </Button>
                </div>
              </div>
            }
          />
          <div className='p-6 flex flex-col gap-4'>
            <div className='p-4 rounded-lg bg-blue-300/50 dark:bg-sky-700/50'>
              <DashboardFilters id='admin-dashboard' onSubmit={() => loadData()} includeServices={false} includeRegions includeSubRegions includeQuarters includeACVs includePlatforms includeForecasts includeStages includeTypes includeTicketTypes includeRequestStatuses includeRequestCategories includeRequestSubCategories />
            </div>
            {loading ? <BarLoader color={theme.primary} backgroundColor={theme.light} height='2' width='100%' /> : <div style={{ height: '1px', marginTop: '1px', backgroundColor: theme.primary }}></div>}
            <Table bordered loading={loading} columns={columns} dataSource={data} onChange={handleChange} pagination={pagination} size='small' expandable={expandableConfig} scroll={{ x: 'max-content' }} className='shadow-sm resizable-table rounded-lg overflow-hidden' />
          </div>
        </LoaderServicesForTenant>
      </PermissionsProvider>
    </StyledDiv>
  );
};

export default AdminDashboard;
