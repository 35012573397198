//import { sleepMs } from 'src/misc/Misc';
import { objToQueryStringParams } from 'src/misc/Misc';
import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';

export const getWatermarkedFile = async (sectionId, itemId, fileId, filename, spPath, customWatermark) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/watermarkedfile?section_id=${sectionId}&item_id=${itemId}&file_id=${fileId}&filename=${encodeURIComponent(filename)}&driveId=b!t6zcu8HEHkiimIcTr95FUe4yWEXARABKsUq8lFt-RuAJb2YwvYn2QJXzW4vPZw5X&rootFolderName=${encodeURIComponent(spPath)}&customWatermark=${customWatermark}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal: AbortSignal.timeout(30000),
    })
  );
};

export const getFiles = async (spPath) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/files?driveId=b!t6zcu8HEHkiimIcTr95FUe4yWEXARABKsUq8lFt-RuAJb2YwvYn2QJXzW4vPZw5X&rootFolderName=${encodeURIComponent(spPath)}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal: AbortSignal.timeout(30000),
    })
  );
};

export const getFileStats = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/filestats`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal: AbortSignal.timeout(30000),
    })
  );
};

//#region "TEACKET"

export const getTeacketFiles = async (year, acc_name, opp_name) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/teacket${objToQueryStringParams({ year, acc_name, opp_name })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

export const postTeacketUploadComplete = async (ticket_id) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/teacket/upload-complete`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ ticket_id }),
    })
  );
};

export const postTeacketCreateUploadSession = async (year, acc_name, opp_name, file_name) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/teacket/upload-session`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ year, acc_name, opp_name, file_name }),
    })
  );
};

export const patchTeacketUpdateFields = async (year, acc_name, opp_name, file_name, fields) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/teacket/fields`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ year, acc_name, opp_name, file_name, fields }),
    })
  );
};

export const patchTeacketUpdatePermission = async (year, acc_name, opp_name, file_name) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/teacket/permission`, {
      method: 'PATCH',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify({ year, acc_name, opp_name, file_name }),
    })
  );
};

export const postTeacketMoveFiles = async (body) =>
  handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/teacket/move`, {
      method: 'POST',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
  );

export const getTeacketFolderName = async (year, acc_name, opp_name) => {
  return handleResponse(
    await fetch(`${ENV.apiUrl}/microsoft/teacket/folder-name${objToQueryStringParams({ year, acc_name, opp_name })}`, {
      method: 'GET',
      headers: { ...(await getAccessHeaders()), 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
