import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { InputNumber } from 'antd';
import TicketTimeTrackingHoursSelectorItem from './TicketTimeTrackingHoursSelectorItem';
import PropTypes from 'prop-types';
import WeekPicker from 'src/components/controls/WeekPicker/WeekPicker';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import dayjs from 'dayjs';
import Space2 from 'src/components/layout/Space2';
import PropertyItem from 'src/components/layout/PropertyItem';
import { TIME_TRACKING_HOURS_PER_DAY } from '../../Misc/misc';

const TicketTimeTrackingHoursSelector = ({ selectedHours, onHoursSelect, selectedDay, onDaySelect }) => {
  const HOURS = [
    { hours: 1, label: '1 hour' },
    { hours: 2, label: '2 hours' },
    { hours: 3, label: '3 hours' },
    { hours: 5, label: '5 hours' },
    { hours: 8, label: '8 hours' },
  ];

  const { getServiceSettingForCurrentTicket } = useContext(TeacketContext);

  const [selectedWeek, setSelectedWeek] = useState(dayjs().utc(true).startOf('week').startOf('day'));
  const [dayList, setDayList] = useState(null);
  const [timeTrackingHoursPerDay] = useState(parseInt(getServiceSettingForCurrentTicket('TimeTrackingHoursPerDay') ?? TIME_TRACKING_HOURS_PER_DAY));
  // const [selectedDay, setSelectedDay] = useState(dayjs().startOf('day'));

  useEffect(() => {
    const week = selectedWeek;
    const dl = [];
    for (let i = 0; i < 5; i++) {
      const day = week.add(i, 'day').startOf('day');
      console.log('day:', day, day.format());
      dl.push(day);
    }
    setDayList(dl);
  }, [selectedWeek]);

  const handleHourSelectorItemOnClick = (hours) => {
    // console.log('handleSelectorItemOnClick:', hours);
    if (!onHoursSelect) return;
    onHoursSelect(hours);
  };

  const handleInputNumberOnChange = (value) => {
    // console.log('handleInputNumberOnChange:', value);
    if (!onHoursSelect) return;
    onHoursSelect(value);
  };

  const handleWeekPickeronChange = (week) => {
    // console.log('handleWeekPickeronChange:', week);
    setSelectedWeek(week);
    if (!onDaySelect) return;
    onDaySelect(null);
  };

  const handleDaySelectorItemOnClick = (day) => {
    // console.log('handleDaySelectorItemOnClick:', day);
    if (!onDaySelect) return;
    onDaySelect(day);
  };

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row gap-2'>
        <div className='w-1/2'>
          <PropertyItem
            label='When?'
            value={
              <div className='flex flex-col items-center my-2'>
                <div>
                  <WeekPicker onChange={handleWeekPickeronChange} value={selectedWeek} minDate={dayjs().subtract(30, 'day').startOf('week').startOf('day')} maxDate={dayjs().endOf('week').endOf('day')} />
                </div>
                <div className='h-6 flex flex-row justify-center items-center'>
                  <FaArrowDown className='animate-move-vertically opacity-20' />
                </div>
                <div className='flex flex-row justify-center gap-2 flex-wrap'>
                  {dayList?.map((x) => (
                    <React.Fragment key={x.format()}>
                      <TicketTimeTrackingHoursSelectorItem
                        value={x}
                        label={
                          <Space2>
                            <span>{x.format('ddd')}</span>
                            <span className='font-light opacity-60 text-[0.6rem]'>{x.format('MMM DD')}</span>
                          </Space2>
                        }
                        onClick={handleDaySelectorItemOnClick}
                        selected={x.isSame(selectedDay)}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            }
          />
        </div>
        <div className='w-1/2'>
          <PropertyItem
            label='How many hours?'
            value={
              <div className='flex flex-col items-center my-2'>
                <div>
                  <InputNumber size='small' min={0.25} max={timeTrackingHoursPerDay} step={1} className='w-20' value={selectedHours} onChange={handleInputNumberOnChange} />
                </div>
                <div className='h-6 flex flex-row justify-center items-center'>
                  <FaArrowUp className='animate-move-vertically opacity-20' />
                </div>
                <div className='flex flex-row justify-center gap-2 flex-wrap'>
                  {HOURS?.filter((x) => x.hours <= timeTrackingHoursPerDay)?.map((x) => (
                    <React.Fragment key={x.hours}>
                      <TicketTimeTrackingHoursSelectorItem value={x.hours} label={x.label} onClick={handleHourSelectorItemOnClick} selected={x.hours === selectedHours} />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

TicketTimeTrackingHoursSelector.propTypes = {
  selectedHours: PropTypes.number,
  onHoursSelect: PropTypes.func,
  selectedDay: PropTypes.object,
  onDaySelect: PropTypes.func,
};

export default TicketTimeTrackingHoursSelector;
