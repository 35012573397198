import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { useNavigate } from 'react-router-dom';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Button, Space } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from './Misc/misc';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import UserTicketListWidget from './Components/UserTicketListWidget/UserTicketListWidget';
import { MdAdd, MdManageAccounts } from 'react-icons/md';
import GraphMyTicketsActive from './Components/GraphMyTicketsActive';
import GraphMyTicketsActiveOldest from './Components/GraphMyTicketsActiveOldest';
import GraphMyOverdue from './Components/GraphMyOverdue';
import GraphMyTicketsByPriority from './Components/GraphMyTicketsByPriority';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import LoaderServicesForTenant from './Loaders/LoaderServicesForTenant';
import Space2 from 'src/components/layout/Space2';
import useTheme from 'src/hooks/useTheme';
import StyledButton from 'src/components/layout/StyledButton';
import useUserInfo from 'src/hooks/useUserInfo';
import { filterItemsBasedOnUserPermissions } from 'src/misc/Session';
import UserTicketListWidgetExtras from './Components/UserTicketListWidget/UserTicketListWidgetExtras';

//import PropTypes from 'prop-types';

const Home = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { permissions: userPermissions } = useUserInfo();

  const { currentTicket } = useContext(TeacketContext);
  const navigate = useNavigate();
  const { theme } = useTheme();

  const componentsGallery = [
    {
      id: 'My Open Tickets',
      col: 0,
      component: (
        <DraggableLayoutItem
          titleExtras={<UserTicketListWidgetExtras />}
          title={
            <Space className='btn'>
              {/* <MdOutlineSecurity /> */}
              My Open Tickets
            </Space>
          }
          centered
        >
          <UserTicketListWidget />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'My Active Tickets by Status',
      col: 1,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>My Active Tickets by Status</Space>} centered>
          <GraphMyTicketsActive />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'My Active Tickets by Priority',
      col: 1,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>My Active Tickets by Priority</Space>} centered>
          <GraphMyTicketsByPriority />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'My Oldest Active Ticket',
      col: 1,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>My Oldest Active Ticket</Space>} centered>
          <GraphMyTicketsActiveOldest />
        </DraggableLayoutItem>
      ),
    },
    {
      id: 'My Overdue Tickets',
      col: 1,
      component: (
        <DraggableLayoutItem title={<Space className='btn'>My Overdue Tickets</Space>} centered>
          <GraphMyOverdue />
        </DraggableLayoutItem>
      ),
      permissions: ['apps.teacket.ticket.edit'],
    },
  ].filter((item) => filterItemsBasedOnUserPermissions(item, userPermissions));
  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <Space2>
              <PageSwitch pageList={pageList} />
              <StyledButton color={theme.textBase} size='small' onClick={() => navigate('/teacket/create-new')}>
                <Space2>
                  <MdAdd />
                  <WhenDesktop>Create new</WhenDesktop>
                </Space2>
              </StyledButton>
            </Space2>
          }
        >
          <PermissionsProvider requiredPermissions={['master.admin']} isControl>
            <Button
              type='text'
              onClick={() => {
                navigate('/master-admin/teacket-services');
              }}
            >
              <MdManageAccounts />
            </Button>
          </PermissionsProvider>
          {layoutItemsSelector}
        </TitleBar>
        <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={2} mainColumnIndex={0} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketHome} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketHomeHidden} enabled={null === currentTicket} />
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// Home.propTypes = {};

export default Home;
