import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import Timeline from 'react-timelines';
import { getAccountJourney } from 'src/api/snowflake';
import 'react-timelines/lib/css/style.css';
import { uuid } from 'short-uuid';
import dayjs from 'dayjs';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import useTheme from 'src/hooks/useTheme';
import styled from 'styled-components';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { getTicketsSameOpp } from 'src/api/teacket';

const StylesWrapper = styled.div`
  .rt-track-key__toggle {
    border: 1px solid #888888;
    border-radius: 0.25rem;
    background-color: #888888;
    scale: 0.75;
    cursor: pointer;
  }

  .rt-controls {
    background-color: transparent;
    padding: 0;
  }

  .rt-controls__content {
    zoom: 0.75;
    border: none;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .rt-layout {
    margin-top: 8px;
    border: 1px solid #888888;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .rt-controls__button {
    border-radius: 0.25rem;
  }

  .rt-track-key__entry {
    padding-left: 12px !important;
  }
`;

const Journey = ({ ticketCreatedDate, ticketClosedDate, ticketId, oppId, accId }) => {
  const [loading, setLoading] = useState(false);
  const { executeAsyncProcess } = useAsyncProcesses();
  const { isDarkMode } = useTheme();

  //#region Constants

  const MIN_ZOOM = 1;
  const MAX_ZOOM = 20;
  const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const MONTHS_PER_YEAR = 12;
  const QUARTERS_PER_YEAR = 4;
  const MONTHS_PER_QUARTER = 3;
  const MAX_TRACK_START_GAP = 4;
  const MAX_ELEMENT_GAP = 8;
  const MAX_MONTH_SPAN = 8;
  const MIN_MONTH_SPAN = 2;
  const ACCOUNT_COLOR = '5867E8';
  const OPPORTUNITY_COLOR = 'FF5D2D';
  const TICKET_COLOR = '00FF00';

  //#endregion

  //#region Defaults

  let startYear = 2018;
  let numYears = 3;
  let numMonths = numYears * MONTHS_PER_YEAR;
  let color = -1;

  //#endregion

  const [data, setData] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(MIN_ZOOM);
  const [tracksById, setTracksById] = useState({});
  const [tracks, setTracks] = useState([]);

  const [timebar, setTimebar] = useState();

  const now = new Date();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setTracks(Object.values(tracksById));
  }, [tracksById]);

  const loadData = async () =>
    await executeAsyncProcess(async () => {
      setLoading(true);
      try {
        if (!accId) return;

        let otherTickets = await getTicketsSameOpp(ticketId);
        let accountJourney = await getAccountJourney(accId);
        if (true === notArrayOrEmpty(accountJourney)) return;
        // Overall timeline start and end
        setStart(dayjs(accountJourney[0].ACCOUNT_CREATEDDATE).subtract(2, 'month').toDate());
        setEnd(dayjs().add(1, 'year').toDate());
        startYear = dayjs(accountJourney[0].ACCOUNT_CREATEDDATE).year();
        numYears = dayjs().year() - startYear + 2;
        numMonths = numYears * MONTHS_PER_YEAR;
        setTimebar(buildTimebar());
        let currentTracksById = {};

        //#region Account Track

        const accountTrack = buildTrack(
          1,
          <div style={{ lineHeight: '1.0rem' }}>
            <div>{accountJourney[0].ACCOUNT_NAME}</div>
            <div className='font-light text-xs opacity-60'>Opportunities: {accountJourney?.length ?? '0'}</div>
          </div>
        );

        // Add account track first
        currentTracksById = {
          [accountTrack.id]: accountTrack,
        };
        accountTrack.tracks = [];

        // Add account element
        const accountElement = buildElement({
          id: accountTrack.id,
          start: dayjs(accountJourney[0].ACCOUNT_CREATEDDATE).toDate(),
          end: dayjs().add(1, 'year').toDate(),
          title: `${accountJourney[0].ACCOUNT_NAME} (This Account)`,
          bgColor: ACCOUNT_COLOR,
          link: `https://genesys.lightning.force.com/lightning/r/Account/${accId}/view`,
        });
        accountTrack.elements = [accountElement];

        //#region Opportunity subtracks

        for (let index = 0; index < accountJourney.length; index++) {
          const opportunity = accountJourney[index];
          const opportunitySubtrack = buildSubtrack(1, index, opportunity.OPPORTUNITY_NAME);
          const isCurrentOpportunity = opportunity.OPPORTUNITY_ID === oppId;

          const opportunityElement = buildElement({
            id: opportunitySubtrack.id,
            start: dayjs(opportunity.OPPORTUNITY_ACTUAL_CREATED_DATE).toDate(),
            end: opportunity.OPPORTUNITY_ACTUAL_CLOSED_DATE ? dayjs(opportunity.OPPORTUNITY_ACTUAL_CLOSED_DATE).toDate() : dayjs().add(1, 'year').toDate(),
            title: (
              <>
                {opportunity.OPPORTUNITY_NAME} <span className='font-light text-xs opacity-60'>({isCurrentOpportunity ? 'This Opportunity' : 'Other Opportunity'})</span>
              </>
            ),
            bgColor: isCurrentOpportunity ? OPPORTUNITY_COLOR : '888888',
            link: `https://genesys.lightning.force.com/lightning/r/Opportunity/${opportunity.OPPORTUNITY_ID}/view`,
          });

          opportunitySubtrack.elements = [opportunityElement];
          accountTrack.tracks.push(opportunitySubtrack); // Add subtrack to parent track
        }

        //#endregion

        //#region Current Opportunity track

        if (oppId) {
          const currentOpportunity = accountJourney.find((o) => o.OPPORTUNITY_ID === oppId);
          const currentOpportunityTrack = {
            id: uuid(),
            title: currentOpportunity.OPPORTUNITY_NAME,
          };

          // Add current opportunity element
          const currentOpportunityElement = buildElement({
            id: currentOpportunityTrack.id,
            start: dayjs(currentOpportunity.OPPORTUNITY_ACTUAL_CREATED_DATE).toDate(),
            end: currentOpportunity.OPPORTUNITY_ACTUAL_CLOSED_DATE ? dayjs(currentOpportunity.OPPORTUNITY_ACTUAL_CLOSED_DATE).toDate() : dayjs().add(1, 'year').toDate(),
            title: (
              <>
                {currentOpportunity.OPPORTUNITY_NAME} <span className='font-light text-xs opacity-60'>(This Opportunity)</span>
              </>
            ),
            bgColor: OPPORTUNITY_COLOR,
            link: `https://genesys.lightning.force.com/lightning/r/Opportunity/${oppId}/view`,
          });
          currentOpportunityTrack.elements = [currentOpportunityElement];
          currentOpportunityTrack.tracks = undefined;
          currentTracksById = {
            ...currentTracksById,
            [currentOpportunityTrack.id]: currentOpportunityTrack,
          };
        }

        //#endregion

        currentTracksById[accountTrack.id] = accountTrack;

        //#endregion

        //#region Ticket Track

        // const ticketTrack = buildTrack(
        //   3,
        //   <div style={{ lineHeight: '1.0rem' }}>
        //     <div>{ticketId}</div>
        //     <div className='font-light text-xs opacity-60'>Other tickets: {otherTickets?.length ?? '0'}</div>
        //   </div>
        // );

        const ticketTrack = {
          id: uuid(),
          title: (
            <div style={{ lineHeight: '1.0rem' }}>
              <div>{ticketId}</div>
              <div className='font-light text-xs opacity-60'>Other tickets: {otherTickets?.length ?? '0'}</div>
            </div>
          ),
        };

        if (true !== notArrayOrEmpty(otherTickets) && ticketTrack) {
          ticketTrack.elements = [];
          ticketTrack.tracks = [];
          ticketTrack.isOpen = false;
        }

        // Add ticket element

        const ticketElement = buildElement({
          id: ticketTrack.id,
          start: dayjs(ticketCreatedDate).toDate(),
          end: ticketClosedDate ? dayjs(ticketClosedDate).toDate() : dayjs().add(1, 'year').toDate(),
          title: (
            <>
              {ticketId} <span className='font-light text-xs opacity-60'>(This Ticket)</span>
            </>
          ),
          bgColor: TICKET_COLOR,
        });
        ticketTrack.elements = [ticketElement];

        currentTracksById[ticketTrack.id] = ticketTrack;

        //#endregion

        //#region Ticket subtracks
        if (true !== notArrayOrEmpty(otherTickets) && ticketTrack) {
          ticketTrack.tracks = [];
          for (const otherTicket of otherTickets) {
            const title = (
              <>
                {otherTicket.id} <span className='font-light text-xs opacity-60'>(Other Ticket)</span>
              </>
            );
            const otherTicketTrack = {
              id: uuid(),
              title,
            };
            const otherTicketElement = buildElement({
              id: otherTicketTrack.id,
              start: dayjs(otherTicket.created_at).toDate(),
              end: otherTicket.closed_at ? dayjs(otherTicket.closed_at).toDate() : dayjs().add(1, 'year').toDate(),
              title,
              link: `/teacket/tickets/${otherTicket.id}`,
              bgColor: '888888',
            });
            otherTicketTrack.elements = [otherTicketElement];
            ticketTrack.tracks.push(otherTicketTrack);
          }
        }
        //#endregion

        setTracksById(currentTracksById);

        setData({
          // opportunityJourney,
          accountJourney,
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    });

  //#region Utils

  const COLORS = ['FF005D', '0085B6', '0BB4C1', '00D49D', 'FEDF03', '233D4D', 'FE7F2D', 'FCCA46', 'A1C181', '579C87'];

  const nextColor = () => {
    color = (color + 1) % COLORS.length;
    return COLORS[color];
  };

  const hexToRgb = (hex) => {
    const v = parseInt(hex, 16);
    const r = (v >> 16) & 255;
    const g = (v >> 8) & 255;
    const b = v & 255;
    return [r, g, b];
  };

  const colourIsLight = (r, g, b) => {
    const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5;
  };

  const addMonthsToYear = (year, monthsToAdd) => {
    let y = year;
    let m = monthsToAdd;
    while (m >= MONTHS_PER_YEAR) {
      m -= MONTHS_PER_YEAR;
      y += 1;
    }
    return { year: y, month: m + 1 };
  };

  const addMonthsToYearAsDate = (year, monthsToAdd) => {
    const r = addMonthsToYear(year, monthsToAdd);
    return new Date(`${r.year}-${r.month}`);
  };

  //#endregion

  //#region Build Helpers

  const buildQuarterCells = () => {
    const v = [];
    for (let i = 0; i < QUARTERS_PER_YEAR * numYears; i += 1) {
      const quarter = (i % 4) + 1;
      const startMonth = i * MONTHS_PER_QUARTER;
      const s = addMonthsToYear(startYear, startMonth);
      const e = addMonthsToYear(startYear, startMonth + MONTHS_PER_QUARTER);
      v.push({
        id: `${s.year}-q${quarter}`,
        title: `Q${quarter} ${s.year}`,
        start: new Date(`${s.year}-${s.month}-01`),
        end: new Date(`${e.year}-${e.month}-01`),
      });
    }
    return v;
  };

  const buildMonthCells = () => {
    const v = [];
    for (let i = 0; i < MONTHS_PER_YEAR * numYears; i += 1) {
      const startMonth = i;
      const start = addMonthsToYearAsDate(startYear, startMonth);
      const end = addMonthsToYearAsDate(startYear, startMonth + 1);
      v.push({
        id: `m${startMonth}`,
        title: MONTH_NAMES[i % 12],
        start,
        end,
      });
    }
    return v;
  };

  const buildTimebar = () => [
    {
      id: 'quarters',
      title: 'Quarters',
      cells: buildQuarterCells(),
      style: {},
    },
    {
      id: 'months',
      title: 'Months',
      cells: buildMonthCells(),
      useAsGrid: true,
      style: {},
    },
  ];

  const buildElement = ({ trackId, start, end, title, bgColor, link }) => {
    bgColor = bgColor || nextColor();
    const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff';

    return {
      id: `t-${trackId}-el-${uuid()}`,
      link,
      title,
      start,
      end,
      style: {
        backgroundColor: `#${bgColor}`,
        color,
        borderRadius: '4px',
        boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
        textTransform: 'capitalize',
      },
    };
  };

  const buildTrackStartGap = () => Math.floor(Math.random() * MAX_TRACK_START_GAP);

  const buildElementGap = () => Math.floor(Math.random() * MAX_ELEMENT_GAP);

  const buildElements = (trackId) => {
    const v = [];
    let i = 1;
    let month = buildTrackStartGap();

    while (month < numMonths) {
      let monthSpan = Math.floor(Math.random() * (MAX_MONTH_SPAN - (MIN_MONTH_SPAN - 1))) + MIN_MONTH_SPAN;

      if (month + monthSpan > numMonths) {
        monthSpan = numMonths - month;
      }

      const start = addMonthsToYearAsDate(startYear, month);
      const end = addMonthsToYearAsDate(startYear, month + monthSpan);
      v.push(
        buildElement({
          trackId,
          start,
          end,
          i,
        })
      );
      const gap = buildElementGap();
      month += monthSpan + gap;
      i += 1;
    }

    return v;
  };

  const buildSubtrack = (trackId, subtrackId, title) => ({
    id: `track-${trackId}-${subtrackId}`,
    title,
    elements: buildElements(subtrackId),
  });

  const buildTrack = (trackId, title) => {
    return {
      id: `track-${trackId}`,
      title,
      elements: [],
      tracks: [],
      isOpen: false,
    };
  };

  //#endregion

  //#region Handle Functions

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  const handleZoomIn = () => {
    setZoom(Math.min(zoom + 1, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoom(Math.max(zoom - 1, MIN_ZOOM));
  };

  const handleToggleTrackOpen = (track) => {
    setTracksById((prevTracksById) => ({
      ...prevTracksById,
      [track.id]: {
        ...prevTracksById[track.id],
        isOpen: !prevTracksById[track.id].isOpen,
      },
    }));
  };

  const handleClickElement = (element) => {
    console.log('click', element);
    if (element.link) {
      window.open(element.link, '_blank');
    }
  };

  //#endregion

  if (loading) {
    return <FullScreenSpin title='Please wait' subtitle='Loading journey details...' height='300px' />;
  }

  return (
    <StylesWrapper>
      <div style={{ filter: isDarkMode ? 'invert(1)' : null }}>
        {data && (
          <Timeline
            scale={{
              start,
              end,
              zoom,
              zoomMin: MIN_ZOOM,
              zoomMax: MAX_ZOOM,
            }}
            isOpen={open}
            toggleOpen={handleToggleOpen}
            zoomIn={handleZoomIn}
            zoomOut={handleZoomOut}
            tracks={tracks}
            timebar={timebar}
            now={now}
            toggleTrackOpen={handleToggleTrackOpen}
            enableSticky={true}
            scrollToNow={true}
            clickElement={handleClickElement}
          />
        )}
      </div>
    </StylesWrapper>
  );
};

Journey.propTypes = {
  ticketId: PropTypes.string.isRequired,
  ticketCreatedDate: PropTypes.string.isRequired,
  ticketClosedDate: PropTypes.string,
  oppId: PropTypes.string,
  accId: PropTypes.string.isRequired,
};

export default Journey;
