import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Space } from 'antd';

const FieldBoolean = ({ propName, fieldDef, defaultValue, onChange }) => {
  return (
    <div>
      <Space>
        <div>
          <Checkbox
            defaultChecked={defaultValue}
            onChange={(e) => {
              if (!onChange) return;
              onChange({ propName, value: e.target.checked });
            }}
          />
        </div>
        <div>{fieldDef.description}</div>
        {fieldDef?.details && <span className='text-xs opacity-60 ml-2'>{fieldDef?.details}</span>}
      </Space>
    </div>
  );
};

FieldBoolean.propTypes = {
  propName: PropTypes.string,
  fieldDef: PropTypes.object,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default FieldBoolean;
