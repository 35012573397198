import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import { CloseOutlined } from '@ant-design/icons';

const DashboardFiltersUsersTag = ({ user_id, user_name, role, onDelete }) => {
  const { theme } = useTheme();

  const handleOnDelete = () => {
    if (!user_id) return;
    if (!onDelete) return;
    onDelete(user_id, role);
  };

  return (
    <div className='flex flex-row gap-0 py-1 px-0 rounded-[0.25rem]' style={{ backgroundColor: theme.backgroundTag }}>
      <div className='flex flex-col gap-0 pl-2'>
        <div style={{ lineHeight: '0.8rem' }} className='text-[0.8rem]'>
          {user_name}
        </div>
        <div style={{ lineHeight: '0.6rem' }} className='text-[0.6rem] font-light opacity-60'>
          {role}
        </div>
      </div>
      <div className='px-1'>
        <CloseOutlined onClick={handleOnDelete} className='text-[0.6rem] cursor-pointer opacity-60 hover:scale-110 hover:opacity-100' />
      </div>
    </div>
  );
};

DashboardFiltersUsersTag.propTypes = {
  user_id: PropTypes.string,
  user_name: PropTypes.string,
  role: PropTypes.string,
  onDelete: PropTypes.func,
};

export default DashboardFiltersUsersTag;
