import React, { useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { getServicesForTenant } from 'src/api/teacket';
import FullScreenSpin from 'src/components/layout/FullScreenSpin';
import PropTypes from 'prop-types';

const LoaderServicesForTenant = ({ children }) => {
  const { servicesForTenant, setServicesForTenant, supervisorFiltersServicesForTenant, setSupervisorFiltersServicesForTenant } = useContext(TeacketContext);
  const { executeAsyncProcess } = useAsyncProcesses();

  useEffect(() => {
    if (null === servicesForTenant) loadData();
  }, []);

  const loadData = async () => {
    console.log('Loading services for the tenant');
    await executeAsyncProcess(async () => {
      try {
        const { servicesForTenant, supervisorFiltersServicesForTenant } = await getServicesForTenant();
        console.log('Services for the tenant loaded:', { servicesForTenant, supervisorFiltersServicesForTenant });
        setServicesForTenant(servicesForTenant);
        setSupervisorFiltersServicesForTenant(supervisorFiltersServicesForTenant);
      } catch (error) {
        console.error(error);
      }
    });
  };

  if (!servicesForTenant || !supervisorFiltersServicesForTenant) {
    return <FullScreenSpin title='Please wait' subtitle='Loading services' />;
  }

  return <>{children}</>;
};

LoaderServicesForTenant.propTypes = {
  children: PropTypes.node,
};

export default LoaderServicesForTenant;
