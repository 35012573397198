import React, { useState, useContext, useEffect } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import { Tooltip, Button } from 'antd';
import { TfiAnnouncement } from 'react-icons/tfi';
import WhenDesktop from '../layout/WhenDesktop';
import { useInterval } from 'react-interval-hook';
import { getFile } from 'src/api/common';
//import PropTypes from 'prop-types';

const VersionDetector = () => {
  const { appRelease, setAppRelease } = useContext(GlobalContext);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    checkForNewRelease();
  }, []);

  useInterval(() => {
    checkForNewRelease();
  }, 60000);

  const checkForNewRelease = async () => {
    try {
      if (newVersionAvailable) return; // if there is a new version available, do not check again
      const currentRelease = await getFile(window.location.origin + '/release.json');
      if (!currentRelease?.releaseDate) throw new Error('currentRelease.releaseDate is not defined');
      if (!appRelease) {
        setAppRelease({ ...currentRelease });
        return;
      }
      if (appRelease.releaseDate !== currentRelease.releaseDate) {
        setNewVersionAvailable(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!newVersionAvailable) return null;

  return (
    <Tooltip title={<>Click to re-load the page.</>}>
      <Button className='animate-pulse' type='primary' icon={<TfiAnnouncement />} onClick={() => window.location.reload()}>
        <WhenDesktop>New version available!</WhenDesktop>
      </Button>
    </Tooltip>
  );
};

VersionDetector.propTypes = {};

export default VersionDetector;
