import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import Space2 from 'src/components/layout/Space2';
import { Button, Select } from 'antd';
import { MdRefresh } from 'react-icons/md';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { notEmptyArray, notArrayOrEmpty, getDistinct1, textSorterStrArray } from 'src/misc/Misc';
//import PropTypes from 'prop-types';

const UserTicketListWidgetExtras = () => {
  const { servicesForTenant, setUserTicketListRefresh, userTicketRole, setUserTicketRole } = useContext(TeacketContext);
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    // console.log('servicesForTenant:', servicesForTenant);
    if (true === notArrayOrEmpty(servicesForTenant)) return;
    let rl = [];
    for (const service of servicesForTenant) {
      if (true === notEmptyArray(service?.props?.roleList)) rl = rl.concat(service.props.roleList.map((x) => x.role));
    }
    rl = getDistinct1(rl)?.sort((a, b) => textSorterStrArray(a, b));
    setRoleList(rl);
  }, [servicesForTenant]);

  return (
    <Space2>
      <Button size='small' onClick={() => setUserTicketListRefresh(true)}>
        <Space2 className='btn'>
          <MdRefresh />
          <WhenDesktop>Refresh</WhenDesktop>
        </Space2>
      </Button>
      <WhenDesktop>
        <Select
          allowClear
          size='small'
          className='w-44 text-left'
          placeholder='[All roles]'
          value={userTicketRole}
          onChange={(v) => setUserTicketRole(v)}
          options={roleList?.map((x) => {
            return { value: x, label: x };
          })}
        />
      </WhenDesktop>
    </Space2>
  );
};

UserTicketListWidgetExtras.propTypes = {};

export default UserTicketListWidgetExtras;
