import React, { useState, useContext } from 'react';
import { FaRegClock, FaRegCalendar } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import TicketTimeTrackingSubmit from './TicketTimeTrackingSubmit';
import TicketTimeTrackingEntries from './TicketTimeTrackingEntries';
import CondensedTabs from 'src/components/layout/CondensedTabs';
import TeacketContext from 'src/context/TeacketContext';
//import PropTypes from 'prop-types';

const TicketTimeTracking = () => {
  const [currentTab, setCurrentTab] = useState('submit');
  const { currentTicketMembers, getCurrentTicketService } = useContext(TeacketContext);

  const noValidMembers = () => {
    if (!currentTicketMembers) {
      return true;
    }
    if (currentTicketMembers.length === 0) {
      return true;
    }

    const currentTicketService = getCurrentTicketService();
    const { roleList } = currentTicketService?.props ?? {};

    const result = currentTicketMembers.filter((member) =>
      roleList
        .filter((role) => !role.excludeFromTimeTracking)
        .map((role) => role.role)
        .includes(member.role)
    );
    if (result.length > 0) return false;
    else return true;

    // If there is only Requester role assigned to the ticket, hide the time tracking
    // if (currentTicketMembers.length === 1) {
    //   const currentTicketService = getCurrentTicketService();
    //   if (!currentTicketService) return false;
    //   const { roleList } = currentTicketService?.props ?? {};
    //   const requesterRole = roleList.find((role) => role.isRequester);
    //   if (currentTicketMembers[0].role === requesterRole?.role) {
    //     return true;
    //   }
    // }

    //return false;
  };

  return (
    <>
      {!noValidMembers() ? (
        <div>
          <CondensedTabs
            size='small'
            type='card'
            activeKey={currentTab}
            onChange={(key) => setCurrentTab(key)}
            items={[
              {
                key: 'submit',
                label: (
                  <Space2>
                    <FaRegClock />
                    Submit time
                  </Space2>
                ),
              },
              {
                key: 'entries',
                label: (
                  <Space2>
                    <FaRegCalendar />
                    Time entries
                  </Space2>
                ),
              },
            ]}
          />

          {currentTab === 'submit' && <TicketTimeTrackingSubmit />}
          {currentTab === 'entries' && <TicketTimeTrackingEntries />}
        </div>
      ) : (
        <p className='text-xs text-center'>No members assigned.</p>
      )}
    </>
  );
};

TicketTimeTracking.propTypes = {};

export default TicketTimeTracking;
