import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import PropTypes from 'prop-types';
import TicketTimeTrackingHoursSelectorItem from '../TicketTimeTracking/TicketTimeTrackingHoursSelectorItem';
import dayjs from 'dayjs';
import Space2 from 'src/components/layout/Space2';
import { Button, Skeleton, message } from 'antd';
import { FaRegClock } from 'react-icons/fa6';
import { IoMdWarning } from 'react-icons/io';
import useUserInfo from 'src/hooks/useUserInfo';
import { getTicketTimeTrackingDate, postTicketTimeTracking, getTicketTimeTrackingTotal } from 'src/api/teacket';
import { v4 as uuid } from 'uuid';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { sleepMs } from 'src/misc/Misc';
import Smile from 'src/components/layout/Smile';
import { TIME_TRACKING_HOURS_PER_DAY } from '../../Misc/misc';

const UserTicketListHoursSubmit = ({ ticketId, serviceId, onSubmit }) => {
  const { id: userId, name: userName } = useUserInfo();
  const { executeAsyncProcess, isBusy } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { getServiceSettingForServiceId } = useContext(TeacketContext);

  const HOURS = [
    { hours: 1, label: '1 hr' },
    { hours: 2, label: '2 hrs' },
    { hours: 3, label: '3 hrs' },
    { hours: 5, label: '5 hrs' },
    { hours: 8, label: '8 hrs' },
  ];

  const [reportedDate, setReportDate] = useState(null);
  const [selectedHours, setSelectedHours] = useState(1);
  const [selectedWeek] = useState(dayjs().utc(true).startOf('week').startOf('day'));
  const [dayList, setDayList] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [loadingReportedDate, setLoadingReportedDate] = useState(false);
  const [showSmileIcon, setShowSmileIcon] = useState(false);
  const [timeTrackingHoursPerDay] = useState(parseInt(getServiceSettingForServiceId(serviceId, 'TimeTrackingHoursPerDay') ?? TIME_TRACKING_HOURS_PER_DAY));

  useEffect(() => {
    setSelectedDay(dayjs().utc(true).startOf('day'));
  }, []);

  useEffect(() => {
    if (!selectedDay) return;
    loadReportedDate();
  }, [selectedDay]);

  useEffect(() => {
    const week = selectedWeek;
    const dl = [];
    for (let i = 0; i < 5; i++) {
      const day = week.add(i, 'day').startOf('day');
      console.log('day:', day, day.format());
      dl.push(day);
    }
    setDayList(dl);
  }, [selectedWeek]);

  const loadReportedDate = async () => {
    console.log('loadReportedDate:', ticketId, userId, selectedDay);
    setLoadingReportedDate(true);
    setReportDate(null);
    try {
      const resp = await getTicketTimeTrackingDate(ticketId, userId, selectedDay);
      setReportDate(resp);
    } catch (error) {
      console.log(error);
    }
    setLoadingReportedDate(false);
  };

  const handleHourSelectorItemOnClick = (hours) => {
    console.log('handleSelectorItemOnClick:', hours);
    setSelectedHours(hours);
  };

  const handleDaySelectorItemOnClick = (day) => {
    console.log('handleDaySelectorItemOnClick:', day);
    setSelectedDay(day);
  };

  const handleSubmit = () => {
    console.log('handleSubmit:', userId, selectedHours);

    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Submitting hours...',
          duration: 0,
        });
        await postTicketTimeTracking(ticketId, userId, selectedHours, selectedDay.format());
        const resultTotal = await getTicketTimeTrackingTotal(ticketId);
        setSelectedHours(1);
        setSelectedDay(null);
        messageApi.open({
          key,
          type: 'success',
          content: 'Hours submitted',
          duration: 3,
        });
        triggetShowingSmileIcon(resultTotal);
      } catch (error) {
        console.log(error);
        messageApi.destroy(key);
      }
    });
  };

  const triggetShowingSmileIcon = async (resultTotal) => {
    setShowSmileIcon(true);
    await sleepMs(1000);
    setShowSmileIcon(false);
    if (onSubmit) onSubmit(resultTotal);
  };

  const canSubmitMore = () => {
    console.log('canPostMore()', timeTrackingHoursPerDay);
    if (!timeTrackingHoursPerDay) return false;
    if (loadingReportedDate) return false;
    if (!userId) return false;
    if (!selectedHours) return false;
    if (!selectedDay) return false;
    if ((reportedDate?.hours ?? 0) + selectedHours > timeTrackingHoursPerDay) return false;
    return true;
  };

  const isLimitReached = () => (reportedDate?.hours ?? 0) + selectedHours > timeTrackingHoursPerDay;

  return (
    <div className={`flex flex-col gap-2 ${isBusy ? ' disabled' : null}`}>
      {contextHolder}
      <div className='flex flex-row gap-2 justify-center'>
        {HOURS?.filter((x) => x.hours <= timeTrackingHoursPerDay)?.map((x) => (
          <React.Fragment key={x.hours}>
            <TicketTimeTrackingHoursSelectorItem value={x.hours} label={x.label} onClick={handleHourSelectorItemOnClick} selected={x.hours === selectedHours} />
          </React.Fragment>
        ))}
      </div>
      <div className='flex flex-row gap-2 justify-center'>
        {dayList?.map((x) => (
          <React.Fragment key={x.format()}>
            <TicketTimeTrackingHoursSelectorItem
              value={x}
              label={
                <Space2>
                  <span>{x.format('ddd')}</span>
                  {/* <span className='font-light opacity-60 text-[0.6rem]'>{x.format('MMM DD')}</span> */}
                </Space2>
              }
              onClick={handleDaySelectorItemOnClick}
              selected={x.isSame(selectedDay)}
            />
          </React.Fragment>
        ))}
      </div>

      <div className='flex flex-row gap-1 justify-between'>
        <Space2 className={`overflow-hidden ${reportedDate ? (true === isLimitReached() ? 'text-red-600 dark:text-red-400' : 'text-orange-600 dark:text-orange-300') : null} ${loadingReportedDate ? 'animate-pulse' : null}`}>
          {selectedHours && userId && selectedDay && !loadingReportedDate ? (
            <>
              <div>{reportedDate ? <IoMdWarning /> : <FaRegClock />}</div>
              <div style={{ lineHeight: '0.8rem', fontSize: '0.8rem' }} className='text-sm'>
                {reportedDate ? (
                  <div>
                    <span className='font-light opacity-80' style={{ fontStretch: 'condensed' }}>
                      Already reported
                    </span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {reportedDate.hours} {reportedDate.hours >= 2 ? 'hrs' : 'hr'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                ) : (
                  <div>
                    <span className='font-light opacity-80'>Submit</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedHours} {selectedHours >= 2 ? 'hrs' : 'hr'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                )}
                <div>
                  <span className='font-light opacity-80'>for</span>{' '}
                  <span className='font-bold' style={{ fontStretch: 'condensed', overflow: 'hidden' }}>
                    {userName}
                  </span>
                </div>
                {true === isLimitReached() && (
                  <div className='font-light opacity-80' style={{ fontStretch: 'condensed' }}>
                    Daily limit is {timeTrackingHoursPerDay} hrs
                  </div>
                )}
              </div>
            </>
          ) : (
            <Skeleton.Input size='small' style={{ width: '150px' }} />
          )}
        </Space2>

        <div className='relative'>
          <div className='absolute z-50 flex flex-row justify-center mt-[-37px] w-full'>
            <Smile show={showSmileIcon} label='Thank you!' />
          </div>

          <Button type='primary' size='small' className={'px-2'} onClick={handleSubmit} disabled={false === canSubmitMore()}>
            {selectedHours && userId && selectedDay ? (
              <div style={{ lineHeight: '1.1rem' }} className='text-sm'>
                +
                <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                  {selectedHours} {selectedHours >= 2 ? 'hrs' : 'hr'}
                </span>
              </div>
            ) : (
              <span className='text-sm font-light opacity-80'>Submit</span>
            )}
          </Button>
        </div>
      </div>

      {/* <div className='relative'>
        <div className='absolute z-50 flex flex-row justify-center mt-[-37px] w-full'>
          <Smile show={showSmileIcon} label='Thank you!' />
        </div>
        <Button type={reportedDate ? 'text' : 'primary'} size='large' className={'w-full'} onClick={handleSubmit} disabled={!selectedHours || !selectedDay || loadingReportedDate || reportedDate}>
          <Space2 className={` ${reportedDate ? 'text-green-900 dark:text-green-100 opacity-60' : null}`}>
            <div>
              <FaRegClock className={loadingReportedDate ? 'animate-pulse' : null} />
            </div>
            {selectedHours && selectedDay ? (
              <div style={{ lineHeight: '1.1rem' }} className='text-sm'>
                {reportedDate ? (
                  <div>
                    <span className='font-light opacity-80' style={{ fontStretch: 'condensed' }}>
                      Already reported
                    </span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {reportedDate.hours} {reportedDate.hours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                ) : (
                  <div>
                    <span className='font-light opacity-80'>Submit</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedHours} {selectedHours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                )}
                <div>
                  <span className='font-light opacity-80'>for</span>{' '}
                  <span className='font-bold' style={{ fontStretch: 'condensed', overflow: 'hidden' }}>
                    {userName}
                  </span>
                </div>
              </div>
            ) : (
              <span className='text-sm font-light opacity-80'>Submit</span>
            )}
          </Space2>
        </Button>
      </div> */}
    </div>
  );
};

UserTicketListHoursSubmit.propTypes = {
  ticketId: PropTypes.string,
  serviceId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default UserTicketListHoursSubmit;
