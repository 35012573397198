import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import TeacketContext from 'src/context/TeacketContext';
import { Spin } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { getTickets2 } from 'src/api/teacket';
import CondensedTable from 'src/components/layout/CondensedTable';
import Space2 from 'src/components/layout/Space2';
import useTheme from 'src/hooks/useTheme';
import TicketListWithGroupingItem from './TicketListWithGroupingItem';

const TicketListWithGrouping = (props) => {
  const { theme } = useTheme();

  const { dataSource, columns } = props;
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [groupedData, setGroupedData] = useState({});
  const [pageSize] = useState(parseInt(25));
  const [groupDataSource] = useState({});
  const [groupCounts, setGroupCounts] = useState({});
  const [loadingCounts, setLoadingCounts] = useState(false);
  const [groupTotals] = useState({});

  const { ticketList2selectedFilters, ticketListQuery, ticketListCurrentPage, ticketListSorter, setTicketListSorter, ticketListGroupBy } = useContext(TeacketContext);

  // Collapse all groups by default & load counts
  useEffect(() => {
    console.log('ticketListGroupBy:', ticketListGroupBy);

    // No groups? Display items from dataSource
    if (!ticketListGroupBy) {
      setGroupedData({ All: dataSource });
      return;
    }

    // Fetch group counts
    const fetchGroupCounts = async () => {
      if (!ticketListGroupBy) {
        setGroupCounts({});
        return;
      }

      try {
        setLoadingCounts(true);

        const resp = await getTickets2(ticketListCurrentPage, pageSize, ticketListSorter?.field ?? null, ticketListSorter?.order ?? null, ticketListQuery, ticketList2selectedFilters, ticketListGroupBy);
        // console.log('resp:', resp);

        setGroupCounts(resp?.entities || []);
      } catch (error) {
        console.error('Failed to fetch group counts:', error);
      } finally {
        setLoadingCounts(false);
      }
    };

    fetchGroupCounts();
  }, [ticketListGroupBy, dataSource]);

  useEffect(() => {
    console.log('dataSource:', dataSource);
  }, [dataSource]);

  useEffect(() => {
    console.log('groupCounts:', groupCounts);
    if (!groupCounts || !groupCounts.length) return;

    collapseAll();
  }, [groupCounts]);

  useEffect(() => {
    console.log('groupedData:', groupedData);
  }, [groupedData]);

  const collapseAll = () => {
    const grouped = groupCounts.reduce((acc, item) => {
      if (!item[ticketListGroupBy]) {
        return acc;
      }
      const groupKey = item[ticketListGroupBy];

      if (!acc[groupKey]) {
        acc[groupKey] = {
          count: 0,
          accounts: new Set(),
          label: ticketListGroupBy === 'acc_id' ? item.acc_name : item[ticketListGroupBy],
        };
      }

      // Sum the counts
      acc[groupKey].count += parseInt(item.count, 10);

      // Track unique accounts
      if (item.acc_name) {
        acc[groupKey].accounts.add(item.acc_name);
      }

      return acc;
    }, {});

    const sortedEntries = Object.entries(grouped).sort(([keyA, valueA], [keyB, valueB]) => {
      const labelA = valueA.label || keyA;
      const labelB = valueB.label || keyB;
      return labelA.localeCompare(labelB);
    });

    const sortedGrouped = Object.fromEntries(sortedEntries);

    setGroupedData(sortedGrouped);
    if (Object.keys(sortedGrouped).length > 1) {
      const initialCollapsedGroups = Object.keys(sortedGrouped).reduce((acc, group) => {
        acc[group] = true;
        return acc;
      }, {});
      setCollapsedGroups(initialCollapsedGroups);
    }
  };

  const toggleGroupCollapse = async (groupName) => {
    console.log(`toggleGroupCollapse: ticketListGroupBy: ${ticketListGroupBy} = ${groupName}`);
    setCollapsedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handleOnSorterChange = (sorter) => {
    console.log('handleOnSorterChange:', sorter);
    setTicketListSorter(sorter);
  };

  const renderShowTotal = (total, range) => (
    <div className='inline-flex items-center text-sm dark:text-gray-100 dark:bg-gray-800 text-gray-600 bg-gray-50 px-2 py-1 rounded-md'>
      <span>Showing&nbsp;</span>
      <span>
        {range[0]}-{range[1]}
      </span>
      <span className='mx-1'>of</span>
      <span className='font-medium dark:text-blue-400 text-blue-600'>{total}</span>
      <span className='ml-1'>{total > 1 ? 'tickets' : 'ticket'}</span>
    </div>
  );

  return (
    <>
      {loadingCounts ? <Spin size='large' className='mt-2 mb-4 flex justify-center' /> : null}
      {Object.keys(groupedData).map((group, index) => {
        // console.log('group:', group);
        //console.log('stuff:', groupedData[group].label);

        return (
          <>
            <div style={{ backgroundColor: theme.backgroundBase }} key={index}>
              {/* GROUP ROWS */}
              {group !== 'All' && (
                <div key={group} className='py-1 px-2 text-sm cursor-pointer hover:bg-genesys-navy-200 dark:hover:bg-genesys-navy-950' style={{ borderTop: `1px solid ${theme.borderLight}`, borderBottom: !collapsedGroups[group] ? `1px solid ${theme.borderLight}` : null }} onClick={() => toggleGroupCollapse(group)}>
                  <Space2>
                    <div className='w-[18px]'>{Object.keys(groupedData).length > 1 && <>{collapsedGroups[group] ? <RightOutlined className='opacity-40 text-xs' /> : <DownOutlined className='opacity-40 text-xs' />}</>}</div>
                    <div className='font-bold'>{groupedData[group].label || group}</div>
                    <div className='font-light text-xs opa'>{loadingCounts ? <Spin size='small' /> : `${groupedData[group].count || 0}`}</div>
                  </Space2>
                </div>
              )}
              {/* TABLE (EITHER ALL TICKETS OR GROUPED TICKETS) */}
              {!collapsedGroups[group] && (
                <div className={group !== 'All' ? '' : null}>
                  {/* TABLE WITH ONLY GROUPED ITEMS */}
                  {ticketListGroupBy ? (
                    <TicketListWithGroupingItem group={group} columns={columns} />
                  ) : (
                    // TABLE WITH ALL ITEMS (WHEN NO GROUPING)
                    <CondensedTable
                      {...props}
                      pagination={
                        group !== 'All'
                          ? {
                              ...props.pagination,
                              total: groupTotals[group] || groupedData[group]?.count || 0,
                              showTotal: renderShowTotal,
                            }
                          : props.pagination
                      }
                      name='TicketList'
                      dataSource={groupDataSource[group] || groupedData[group]}
                      columns={columns}
                      onSorterChange={handleOnSorterChange}
                    />
                  )}
                </div>
              )}
            </div>
            <div style={{ borderTop: `1px solid ${theme.borderLight}` }}></div>
          </>
        );
      })}
    </>
  );
};

TicketListWithGrouping.propTypes = {
  noDataMessage: PropTypes.string,
  ticketListGroupBy: PropTypes.string,
  dataSource: PropTypes.array,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.object,
};

export default TicketListWithGrouping;
