import React, { Component } from 'react';
import TeacketContext from './TeacketContext';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { hasMasterAdminRights, hasTicketAdminRights /*, hasSupervisorRights */ } from '../components/pages/Teacket/Misc/misc';

var currentTicketConversationGlobal = null;

export default class GlobalProvider extends Component {
  currentTicketSharepointFilesFilterOptions = {
    this: 'this',
    thisAndUnassigned: 'this-and-unassigned',
    all: 'all',
  };

  dermineCurrentTicketSharepointFilesFiltered = (files, filter) => {
    if (true === notArrayOrEmpty(files)) return null;
    if (!this.state.currentTicket) return null;

    switch (filter) {
      case this.currentTicketSharepointFilesFilterOptions.this:
        return files.filter((f) => f.listItem?.fields?.RequestID === this.state.currentTicket.id);
      case this.currentTicketSharepointFilesFilterOptions.thisAndUnassigned:
        return files.filter((f) => f.listItem?.fields?.RequestID === this.state.currentTicket.id || !f.listItem?.fields?.RequestID);
      case this.currentTicketSharepointFilesFilterOptions.all:
        return files;
      default:
        return null;
    }
  };

  state = {
    // SERVICES
    servicesForTenant: null,
    supervisorFiltersServicesForTenant: null,
    // CURRENT TICKET
    currentTicket: null,
    currentTicketConversation: null,
    currentTicketIsMasterTenant: null,
    currentTicketMembers: null,
    currentTicketReadOnly: true, // whole ticket read only
    currentTicketReadOnlyWidgetList: null, // read only widgets
    currentTicketHistory: null,
    currentTicketHistoryForceReload: null,
    currentTicketCustomFields: null,
    currentTicketS3FilesList: null,
    currentTicketTimeTrackingForceReload: null,
    currentTicketTimeTrackingTotal: null,
    activeViewers: [],
    currentTicketOppDetails: null,
    currentTicketCompletionDates: null,
    currentTicketSharepointFilesOppAccUpdated: null,
    currentTicketSharepointFiles: null,
    currentTicketSharepointFilesFiltered: null,
    currentTicketSharepointFilesRequestIds: false,
    currentTicketSharepointFilesFilter: this.currentTicketSharepointFilesFilterOptions.thisAndUnassigned,
    currentTicketSharepointFilesFilterOptions: this.currentTicketSharepointFilesFilterOptions,
    currentTicketNumberOfQuestions: null,
    currentTicketProperties: null,
    // USER TICKET LIST
    userTicketList: null,
    userTicketListRefresh: false,
    userTicketRole: null,
    // MY TICKETS
    myTickets: null,
    myTicketsPageSize: 8,
    myTicketsCurrentPage: 1,
    myTicketsTotal: 0,
    myTicketsSelectedTicket: null,
    myTicketsLayoutItemsSelector: null,
    myTicketsQuery: null,
    myTicketsStatuses: null,
    myTicketsPriorities: null,
    myTicketsRoles: null,
    // DASHBOARD
    dashboardLayoutItemsSelector: null,
    dashboardListServices: null,
    dashboardStartDate: null,
    dashboardEndDate: null,
    dashboardDataTickets: null,
    dashboardRegions: null,
    dashboardSubRegions: null,
    dashboardQuarters: null,
    dashboardACVs: null,
    dashboardPlatforms: null,
    dashboardForecasts: null,
    dashboardStages: null,
    dashboardAccountGroupings: null,
    dashboardAccounts: null,
    dashboardOpportunities: null,
    dashboardMarketSegments: null,
    dashboardTypes: null,
    dashboardRequestTypes: null,
    dashboardRequestStatuses: null,
    dashboardRequestCategories: null,
    dashboardRequestSubCategories: null,
    dashboardMembers: null,
    filterRequesters: null,
    filterProjectManagers: null,
    filterPrimaryTeamMembers: null,
    filterSubjectMatterExperts: null,
    filterTeamMembers: null,
    dashboardDepartments: null,
    dashboardWrapUpCodes: null,
    dashboardDirectIndirects: null,
    dashboardCustomerTypes: null,
    dashboardRegionalCareOffers: null,
    dashboardOnlyActive: null,
    filterIncludeOpportunityFlag: null,

    // TICKET LIST
    ticketListSelectedTicket: null,
    ticketList: null,
    ticketListTotal: null,
    ticketListCurrentPage: null,
    ticketListQuery: null,
    ticketListServices: null,
    ticketListPriorities: null,
    ticketListStatuses: null,
    ticketListUsers: null,
    ticketListQuerying: false,
    ticketListStartDate: null,
    ticketListEndDate: null,
    ticketListGroupBy: null,
    ticketListTypes: null,
    ticketListRefresh: false,
    ticketListSorter: null,

    // TICKET LIST CONTROL
    visibleColumns: JSON.parse(localStorage.getItem('spc.layout.teacket.visibleColumns')) || {
      id: true,
      title: true,
      account: true,
      region: true,
      members: true,
      category: true,
      priority: true,
      status: true,
      created_at: true,
      closed_at: true,
    },
    columnOrder: JSON.parse(localStorage.getItem('spc.layout.teacket.columnOrder')) || ['id', 'title', 'account', 'region', 'members', 'category', 'priority', 'status', 'created_at', 'closed_at', 'need_completed_by', 'forecasted_date'],

    // TICKET LIST 2
    ticketList2selectedFilters: null,
    ticketList2LoadData: false,
    ticketList2Refresh: false,
    ticketList2Export: false,

    // OTHERS
    dashboardUser: null,
    ticketAfterUpdateSync: null,
    ticketTypes: [],
  };

  render() {
    return (
      <TeacketContext.Provider
        value={{
          ...this.state,

          // ### SERVICES ###
          setServicesForTenant: (servicesForTenant) => {
            // Save TicketTypes to the context
            let ticketTypes = [];
            // do for each service
            for (const service of servicesForTenant) {
              if (service?.props?.ticketTypes && Array.isArray(service?.props?.ticketTypes) && service.props.ticketTypes.length > 0) ticketTypes = ticketTypes.concat(service.props.ticketTypes);
            }

            this.setState({ ticketTypes });
            this.setState({ servicesForTenant });
          },
          setSupervisorFiltersServicesForTenant: (supervisorFiltersServicesForTenant) => this.setState({ supervisorFiltersServicesForTenant }),
          getServicePropsForServiceId: (serviceId, propName) => {
            if (!serviceId) return null;
            if (!propName) return null;
            if (true === notArrayOrEmpty(this.state.servicesForTenant)) return null;

            const service = this.state.servicesForTenant.find((s) => s.id === serviceId);
            if (!service?.props) return null;
            return service?.props[propName];
          },
          getServiceSettingForServiceId: (serviceId, settingName) => {
            const service = this.state.servicesForTenant.find((s) => s.id === serviceId);
            if (!service) return null;
            const { settingList } = service.props ?? {};
            if (notArrayOrEmpty(settingList)) return null;
            const setting = settingList.find((s) => s.name === settingName);
            if (!setting?.value) return null;
            return setting.value;
          },
          getWidgetListForCurrentTicket: () => {
            if (!this.state.currentTicket) return null;
            const service = this.state.servicesForTenant.find((s) => s.id === this.state.currentTicket.service_id);
            if (!service) return null;
            const { widgetList } = service.props ?? {};
            if (notArrayOrEmpty(widgetList)) return [];
            return widgetList;
          },
          getWidgetListForServiceId: (service_id) => {
            if (!service_id) return null;
            const service = this.state.servicesForTenant.find((s) => s.id === service_id);
            if (!service) return null;
            const { widgetList } = service.props ?? {};
            if (notArrayOrEmpty(widgetList)) return [];
            return widgetList;
          },
          getServiceSettingForCurrentTicket: (settingName) => {
            if (!this.state.currentTicket) return null;
            const service = this.state.servicesForTenant.find((s) => s.id === this.state.currentTicket.service_id);
            if (!service) return null;
            const { settingList } = service.props ?? {};
            if (notArrayOrEmpty(settingList)) return null;
            const setting = settingList.find((s) => s.name === settingName);
            if (!setting?.value) return null;
            return setting.value;
          },

          // ### CURRENT TICKET STAKEHOLDER DETAILS ###
          setCurrentTicketOppDetails: (props) => {
            const newProps = {
              account_name: props?.ACCOUNT_NAME,
              account_grouping: props?.ACCOUNT_GROUPING,
              region: props?.OPPORTUNITY_COUNTRY_REGION || props?.ACCOUNT_REGION,
              sub_region: props?.OPPORTUNITY_COUNTRY_SUB_REGION,
              currency_iso_code: props?.OPPORTUNITY_CURRENCY_ISO_CODE,
              direct_indirect_Sale: props?.OPPORTUNITY_DIRECT_INDIRECT_SALE,
              dsr_url: props?.OPPORTUNITY_DSR_URL,
              gross_acv_booking: props?.OPPORTUNITY_GROSS_ACV_BOOKING,
              gross_acv_booking_usd: props?.OPPORTUNITY_GROSS_ACV_BOOKING_USD,
              owner_email: props?.OPPORTUNITY_OWNER_EMAIL,
              owner_id: props?.OPPORTUNITY_OWNER_ID ?? null,
              owner_name: props?.OPPORTUNITY_OWNER_NAME,
              product: props?.OPPORTUNITY_PRODUCT,
              sales_segment: props?.OPPORTUNITY_SALES_SEGMENT || props?.ACCOUNT_SALES_SEGMENT,
              sc_email: props?.OPPORTUNITY_SC_EMAIL,
              sc_id: props?.OPPORTUNITY_SC_ID ?? null,
              sc_name: props?.OPPORTUNITY_SC_NAME,
              seats: props?.OPPORTUNITY_SEATS,
              stage_name: props?.OPPORTUNITY_STAGE_NAME,
            };

            this.setState({ currentTicketOppDetails: newProps });
            return newProps;
          },
          updateCurrentTicketProperties: (updates) => {
            this.setState((prevState) => ({
              currentTicketOppDetails: {
                ...prevState.currentTicketOppDetails,
                ...updates,
              },
            }));
          },

          // ### CURRENT TICKET ###

          setCurrentTicket: (ticket, isMaster) => this.setState({ currentTicket: ticket, currentTicketIsMasterTenant: isMaster }),
          updateCurrentTicket: (updates) => {
            const currentTicket = { ...this.state.currentTicket, ...updates };
            this.setState({ currentTicket });
          },
          // updateCurrentTicket: (updates) => {
          //   this.setState((prevState) => ({
          //     currentTicket: {
          //       ...prevState.currentTicket,
          //       ...updates,
          //     },
          //   }));
          // },
          setCurrentTicketConversation: (conversation) => {
            currentTicketConversationGlobal = conversation;
            this.setState({
              currentTicketConversation: conversation,
            });
          },
          addCurrentTicketConversationMessage: (message) => {
            let c = [...currentTicketConversationGlobal].filter((m) => !m.isPosting);
            c.push(message);
            c = c.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            currentTicketConversationGlobal = c;
            this.setState({ currentTicketConversation: c });
          },
          updateCurrentTicketConversationMessage: (message) => {
            message.isPosting = false;
            let c = [...currentTicketConversationGlobal];
            const index = c.findIndex((conv) => conv.id === message.id);
            if (index !== -1) {
              c[index] = { ...c[index], ...message };
              currentTicketConversationGlobal = c;
              this.setState({ currentTicketConversation: c });
            } else {
              console.log(`Conversation message with id ${c.id} not found.`);
            }
          },
          setCurrentTicketMembers: (members) => this.setState({ currentTicketMembers: members }),
          setCurrentTicketReadOnly: (readOnly) => this.setState({ currentTicketReadOnly: readOnly }),
          setCurrentTicketReadOnlyWidgetList: (readOnlyWidgetList) => this.setState({ currentTicketReadOnlyWidgetList: readOnlyWidgetList }),
          setCurrentTicketHistory: (history) => this.setState({ currentTicketHistory: history }),
          setCurrentTicketHistoryForceReload: (forceReload) => this.setState({ currentTicketHistoryForceReload: forceReload }),
          setCurrentTicketCustomFields: (customFields) => this.setState({ currentTicketCustomFields: customFields }),
          setCurrentTicketS3FilesList: (currentTicketS3FilesList) => this.setState({ currentTicketS3FilesList }),
          getCurrentTicketService: () => this.state.servicesForTenant.find((s) => s.id === this.state.currentTicket.service_id),
          setCurrentTicketTimeTrackingForceReload: (forceReload) => this.setState({ currentTicketTimeTrackingForceReload: forceReload }),
          setCurrentTicketTimeTrackingTotal: (total) => this.setState({ currentTicketTimeTrackingTotal: total }),
          setActiveViewers: (activeViewers) => this.setState({ activeViewers }),
          setCurrentTicketCompletionDates: (completionDates) => this.setState({ currentTicketCompletionDates: completionDates }),
          setCurrentTicketSharepointFilesOppAccUpdated: (oppAccUpdated) => this.setState({ currentTicketSharepointFilesOppAccUpdated: oppAccUpdated }),
          setCurrentTicketSharepointFiles: (sharepointFiles) => {
            const filteredFiles = this.dermineCurrentTicketSharepointFilesFiltered(sharepointFiles, this.state.currentTicketSharepointFilesFilter);
            this.setState({ currentTicketSharepointFiles: sharepointFiles, currentTicketSharepointFilesFiltered: filteredFiles });
          },
          setCurrentTicketSharepointFilesRequestIds: (requestIds) => this.setState({ currentTicketSharepointFilesRequestIds: requestIds }),
          setCurrentTicketSharepointFilesFilter: (filter) => {
            const filteredFiles = this.dermineCurrentTicketSharepointFilesFiltered(this.state.currentTicketSharepointFiles, filter);
            this.setState({ currentTicketSharepointFilesFilter: filter, currentTicketSharepointFilesFiltered: filteredFiles });
          },
          setCurrentTicketNumberOfQuestions: (currentTicketNumberOfQuestions) => this.setState({ currentTicketNumberOfQuestions }),
          setCurrentTicketProperties: (fields) => {
            if (true === notArrayOrEmpty(fields)) return;
            let currentTicketProperties = this.state.currentTicketProperties ? { ...this.state.currentTicketProperties } : {};
            for (const field of fields) {
              if (!field?.field) continue;
              currentTicketProperties[field.field] = field;
            }
            this.setState({ currentTicketProperties });
          },
          resetCurrentTicket: () => {
            this.setState({
              currentTicket: null,
              currentTicketConversation: null,
              currentTicketIsMasterTenant: null,
              currentTicketMembers: null,
              currentTicketReadOnly: true,
              currentTicketReadOnlyWidgetList: null,
              currentTicketHistory: null,
              currentTicketS3FilesList: null,
              currentTicketCustomFields: null,
              currentTicketHistoryForceReload: null,
              currentTicketOppDetails: null,
              currentTicketTimeTrackingForceReload: null,
              currentTicketTimeTrackingTotal: null,
              currentTicketCompletionDates: null,
              currentTicketSharepointFilesOppAccUpdated: null,
              currentTicketSharepointFiles: null,
              currentTicketSharepointFilesFiltered: null,
              currentTicketSharepointFilesRequestIds: false,
              currentTicketSharepointFilesFilter: this.currentTicketSharepointFilesFilterOptions.thisAndUnassigned,
              currentTicketNumberOfQuestions: null,
              currentTicketProperties: null,
              activeViewers: [],
            });
          },
          determineIsWidgetReadOnly: (widgetId) => {
            // console.log('determineIsWidgetReadOnly:', widgetId, this.state.currentTicketReadOnly, this.state.currentTicketReadOnlyWidgetList);
            // if (widgetId === 'Stakeholder Details') debugger;
            if (!this.state.currentTicket?.id || !this.state.currentTicket) return false; // User is creating a new ticket
            if (this.state.currentTicketReadOnly) return true; // whole ticket is read only so the widget as well
            return this.state.currentTicketReadOnlyWidgetList?.includes(widgetId); // the widget is read only
          },

          // ### USER TICKET LIST ###
          setUserTicketList: (userTicketList) => this.setState({ userTicketList }),
          setUserTicketListRefresh: (userTicketListRefresh) => this.setState({ userTicketListRefresh }),
          setUserTicketRole: (userTicketRole) => this.setState({ userTicketRole }),

          // ### MY TICKETS ###
          setMyTickets: (myTickets) => this.setState({ myTickets }),
          setMyTicketsCurrentPage: (myTicketsCurrentPage) => this.setState({ myTicketsCurrentPage }),
          setMyTicketsTotal: (myTicketsTotal) => this.setState({ myTicketsTotal }),
          setMyTicketsSelectedTicket: (myTicketsSelectedTicket) => this.setState({ myTicketsSelectedTicket }),
          setMyTicketsLayoutItemsSelector: (myTicketsLayoutItemsSelector) => this.setState({ myTicketsLayoutItemsSelector }),
          setMyTicketsQuery: (myTicketsQuery) => this.setState({ myTicketsQuery }),
          setMyTicketsStatuses: (myTicketsStatuses) => this.setState({ myTicketsStatuses }),
          setMyTicketsPriorities: (myTicketsPriorities) => this.setState({ myTicketsPriorities }),
          setMyTicketsRoles: (myTicketsRoles) => this.setState({ myTicketsRoles }),

          // ### DASHBOARD ###
          setDashboardLayoutItemsSelector: (dashboardLayoutItemsSelector) => this.setState({ dashboardLayoutItemsSelector }),
          setDashboardListServices: (dashboardListServices) => this.setState({ dashboardListServices }),
          setDashboardStartDate: (dashboardStartDate) => this.setState({ dashboardStartDate }),
          setDashboardEndDate: (dashboardEndDate) => this.setState({ dashboardEndDate }),
          setDashboardDataTickets: (dashboardDataTickets) => this.setState({ dashboardDataTickets }),
          setDashboardRegions: (dashboardRegions) => this.setState({ dashboardRegions }),
          setDashboardSubRegions: (dashboardSubRegions) => this.setState({ dashboardSubRegions }),
          setDashboardQuarters: (dashboardQuarters) => this.setState({ dashboardQuarters }),
          setDashboardACVs: (dashboardACVs) => this.setState({ dashboardACVs }),
          setDashboardPlatforms: (dashboardPlatforms) => this.setState({ dashboardPlatforms }),
          setDashboardForecasts: (dashboardForecasts) => this.setState({ dashboardForecasts }),
          setDashboardStages: (dashboardStages) => this.setState({ dashboardStages }),
          setDashboardAccountGroupings: (dashboardAccountGroupings) => this.setState({ dashboardAccountGroupings }),
          setDashboardAccounts: (dashboardAccounts) => this.setState({ dashboardAccounts }),
          setDashboardOpportunities: (dashboardOpportunities) => this.setState({ dashboardOpportunities }),
          setDashboardMarketSegments: (dashboardMarketSegments) => this.setState({ dashboardMarketSegments }),
          setDashboardTypes: (dashboardTypes) => this.setState({ dashboardTypes }),
          setDashboardRequestTypes: (dashboardRequestTypes) => this.setState({ dashboardRequestTypes }),
          setDashboardRequestStatuses: (dashboardRequestStatuses) => this.setState({ dashboardRequestStatuses }),
          setDashboardRequestCategories: (dashboardRequestCategories) => this.setState({ dashboardRequestCategories }),
          setDashboardRequestSubCategories: (dashboardRequestSubCategories) => this.setState({ dashboardRequestSubCategories }),
          setDashboardMembers: (dashboardMembers) => this.setState({ dashboardMembers }),
          setFilterRequesters: (filterRequesters) => this.setState({ filterRequesters }),
          setFilterProjectManagers: (filterProjectManagers) => this.setState({ filterProjectManagers }),
          setFilterPrimaryTeamMembers: (filterPrimaryTeamMembers) => this.setState({ filterPrimaryTeamMembers }),
          setFilterSubjectMatterExperts: (filterSubjectMatterExperts) => this.setState({ filterSubjectMatterExperts }),
          setFilterTeamMembers: (filterTeamMembers) => this.setState({ filterTeamMembers }),
          setDashboardDepartments: (dashboardDepartments) => this.setState({ dashboardDepartments }),
          setDashboardWrapUpCodes: (dashboardWrapUpCodes) => this.setState({ dashboardWrapUpCodes }),
          setDashboardDirectIndirects: (dashboardDirectIndirects) => this.setState({ dashboardDirectIndirects }),
          setDashboardCustomerTypes: (dashboardCustomerTypes) => this.setState({ dashboardCustomerTypes }),
          setDashboardRegionalCareOffers: (dashboardRegionalCareOffer) => this.setState({ dashboardRegionalCareOffer }),
          setDashboardOnlyActive: (dashboardOnlyActive) => this.setState({ dashboardOnlyActive }),
          setFilterIncludeOpportunityFlag: (filterIncludeOpportunityFlag) => this.setState({ filterIncludeOpportunityFlag }),

          // ### TICKET LIST ###
          setTicketListSelectedTicket: (ticketListSelectedTicket) => this.setState({ ticketListSelectedTicket }),
          setTicketList: (ticketList) => this.setState({ ticketList }),
          setTicketListTotal: (ticketListTotal) => this.setState({ ticketListTotal }),
          setTicketListCurrentPage: (ticketListCurrentPage) => this.setState({ ticketListCurrentPage }),
          setTicketListQuery: (ticketListQuery) => this.setState({ ticketListQuery }),
          setTicketListServices: (ticketListServices) => this.setState({ ticketListServices }),
          setTicketListPriorities: (ticketListPriorities) => this.setState({ ticketListPriorities }),
          setTicketListStatuses: (ticketListStatuses) => this.setState({ ticketListStatuses }),
          setTicketListUsers: (ticketListUsers) => this.setState({ ticketListUsers }),
          setTicketListQuerying: (ticketListQuerying) => this.setState({ ticketListQuerying }),
          setTicketListStartDate: (ticketListStartDate) => this.setState({ ticketListStartDate }),
          setTicketListEndDate: (ticketListEndDate) => this.setState({ ticketListEndDate }),
          setTicketListGroupBy: (ticketListGroupBy) => this.setState({ ticketListGroupBy }),
          setTicketListTypes: (ticketListTypes) => this.setState({ ticketListTypes }),
          setTicketListRefresh: (ticketListRefresh) => this.setState({ ticketListRefresh }),
          setTicketListSorter: (ticketListSorter) => this.setState({ ticketListSorter }),

          // ### TICKET LIST CONTROL ###
          setVisibleColumns: (visibleColumns) => this.setState({ visibleColumns }),
          resetVisibleColumns: () => this.setState({ visibleColumns: null }),
          toggleColumnVisibility: (columnKey) => {
            console.log('Toggle column visibility:', columnKey);
            this.setState((prevState) => {
              const updatedColumns = {
                ...prevState.visibleColumns,
                [columnKey]: !prevState.visibleColumns[columnKey],
              };
              localStorage.setItem('spc.layout.teacket.visibleColumns', JSON.stringify(updatedColumns));
              return { visibleColumns: updatedColumns };
            });
          },
          setColumnOrder: (newOrder) => {
            this.setState({ columnOrder: newOrder });
            localStorage.setItem('spc.layout.teacket.columnOrder', JSON.stringify(newOrder));
          },

          // ### TICKET LIST 2 ###
          setTicketList2selectedFilters: (ticketList2selectedFilters) => this.setState({ ticketList2selectedFilters }),
          setTicketList2LoadData: (ticketList2LoadData) => this.setState({ ticketList2LoadData }),
          setTicketList2Refresh: (ticketList2Refresh) => this.setState({ ticketList2Refresh }),
          setTicketList2Export: (ticketList2Export) => this.setState({ ticketList2Export }),

          // ### OTHERS ###
          setDashboardUser: (dashboardUser) => this.setState({ dashboardUser }),
          setTicketAfterUpdateSync: (flag) => this.setState({ ticketAfterUpdateSync: flag }),
          hideInternalMessageButton: (userInfo) => {
            console.log('show/hide Internal Msg button');
            if (!this.state.currentTicket || !this.state.currentTicketMembers || !this.state.servicesForTenant) return true;

            if (hasMasterAdminRights(userInfo.permissions) || hasTicketAdminRights(userInfo.permissions)) {
              //hasSupervisorRights(userInfo.permissions)
              return false;
            }

            const service = this.state.servicesForTenant.find((s) => s.id === this.state.currentTicket?.service_id);
            if (!service.props) return true;
            const rolesList = service.props['roleList'].filter((x) => x.canSeePrivMsgs).map((x) => x.role); // get roles that can see internal messages
            const canSeePrivMsgs = this.state.currentTicketMembers.some((x) => rolesList.includes(x.role) && x.user_id === userInfo.id);
            console.log('Is user egligible to see Private Msgs:', canSeePrivMsgs);
            return !canSeePrivMsgs;
          },
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {this.props.children}
      </TeacketContext.Provider>
    );
  }
}
