import React from 'react';
import PropTypes from 'prop-types';
import { Space, Tree } from 'antd';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import { uuid } from 'short-uuid';

const UserResultantPermissions = ({ resultantPermissions }) => {
  const getTreeDataForPermissions = () => {
    if (true === notArrayOrEmpty(resultantPermissions)) return [];
    const result = [];
    for (const rp of resultantPermissions) {
      const item = {
        key: uuid(),
        title: (
          <Space>
            <div className='font-semibold'>{rp.permission}</div>
            <div className='font-extralight opacity-60 text-xs'>permission</div>
          </Space>
        ),
        children: [],
      };
      if (true === notEmptyArray(rp.assignments)) {
        for (const a of rp.assignments) {
          item.children.push({
            key: uuid(),
            title: (
              <Space>
                <div className='font-semibold'>{a.role}</div>
                <div className='font-extralight opacity-60 text-xs'>role</div>
              </Space>
            ),
            children: Object.keys(a)
              ?.filter((k) => null !== a[k])
              ?.map((k) => {
                return {
                  key: uuid(),
                  title: (
                    <Space>
                      <div className='font-extralight opacity-80'>{k}:</div>
                      <div className='font-semibold'>{Array.isArray(a[k]) ? a[k].join(', ') : a[k]}</div>
                    </Space>
                  ),
                };
              }),
          });
        }
      }

      result.push(item);
    }

    return result;
  };

  const getTreeDataForRoles = () => {
    if (true === notArrayOrEmpty(resultantPermissions)) return [];

    // console.log('resultantPermissions:', resultantPermissions);
    const result = [];

    const roleList = [];
    const assignementList = [];
    for (const rp of resultantPermissions) {
      if (true === notEmptyArray(rp.assignments)) {
        for (const a of rp.assignments) {
          const { role, assignment_id } = a;
          if (!role) continue;
          if (!roleList.includes(role)) roleList.push(role);
          if (!assignementList.some((x) => x.assignment_id === assignment_id)) assignementList.push(a);
        }
      }
    }

    // console.log('roleList:', roleList);
    // console.log('assignementList:', assignementList);

    for (const role of roleList) {
      const item = {
        key: role,
        title: (
          <Space>
            <div className='font-semibold'>{role}</div>
            <div className='font-extralight opacity-60 text-xs'>role</div>
          </Space>
        ),
        children: [],
      };

      const assigments = assignementList.filter((x) => x.role === role);
      for (const a of assigments) {
        item.children.push({
          key: a.assignment_id,
          title: (
            <Space>
              <div className='font-semibold'>{a.assignment_id}</div>
              <div className='font-extralight opacity-60 text-xs'>assignment</div>
            </Space>
          ),
          children: Object.keys(a)
            ?.filter((k) => null !== a[k])
            ?.map((k) => {
              return {
                key: uuid(),
                title: (
                  <Space>
                    <div className='font-extralight opacity-80'>{k}:</div>
                    <div className='font-semibold'>{Array.isArray(a[k]) ? a[k].join(', ') : a[k]}</div>
                  </Space>
                ),
              };
            }),
        });
      }
      result.push(item);
    }

    return result;
  };

  // const extractRoles = () => {
  //   if (true === notArrayOrEmpty(resultantPermissions)) return [];
  //   const result = new Set();
  //   for (const rp of resultantPermissions) {
  //     if (true === notEmptyArray(rp.assignments)) {
  //       for (const a of rp.assignments) {
  //         result.add(a.role);
  //       }
  //     }
  //   }

  //   return Array.from(result).sort();
  // };

  return (
    <>
      {/* <div className='mb-2'>Roles:</div>
      <div className='flex flex-wrap gap-2 mb-2'>
        {extractRoles().map((r) => (
          <Tag key={r} color='gray' className='rounded-full px-2 py-1'>
            {r}
          </Tag>
        ))}
      </div> */}
      <div className='mb-2'>Roles:</div>
      <Tree treeData={getTreeDataForRoles()} />
      <div className='mb-2'>Permissions:</div>
      <Tree treeData={getTreeDataForPermissions()} />
    </>
  );
};

UserResultantPermissions.propTypes = {
  resultantPermissions: PropTypes.array,
};

export default UserResultantPermissions;
