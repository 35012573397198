import React, { useContext, useEffect, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space, Tooltip } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../Misc/misc';
import TicketListControl2 from './components/TicketListControl2';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import Space2 from 'src/components/layout/Space2';
import DashboardFilters from '../Dashboard/components/DashboardFilters/DashboardFilters';
import { MdRefresh } from 'react-icons/md';
import StyledButton from 'src/components/layout/StyledButton';
import useTheme from 'src/hooks/useTheme';
import { FaFileCsv } from 'react-icons/fa6';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import TicketList2Grouping from './components/TicketList2Grouping';

//import PropTypes from 'prop-types';

const TicketList2 = () => {
  const { theme } = useTheme();
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();
  const { setTicketList2selectedFilters, setTicketList2LoadData, setTicketList2Refresh, setTicketList2Export } = useContext(TeacketContext);

  const [getColumnsSelector, setGetColumnsSelector] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Ticket List',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  const handleDashboardFiltersOnSubmit = () => setTicketList2LoadData(true);
  const handleDashboardFiltersOnChange = (values) => setTicketList2selectedFilters(values);
  const handleRefreshTicketList = () => setTicketList2Refresh(true);
  const handleExportTicketList = () => setTicketList2Export(true);
  const handleGetColumnsSelector = (getColumnsSelector) => setGetColumnsSelector(getColumnsSelector);

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.list.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <Space2>
              <PageSwitch pageList={pageList} />
            </Space2>
          }
          defaultExpanded={true}
          bottomExtras={
            <DashboardFilters
              id='ticket-list-2'
              collapsible={false}
              onSubmit={handleDashboardFiltersOnSubmit}
              onFilterChange={handleDashboardFiltersOnChange}
              allowSave
              allowSearch
              allowGrouping={false}
              includeServices
              includeRegions
              includeSubRegions
              includePlatforms
              includeStages
              includeTicketTypes
              includeRequestStatuses
              includeRequestCategories
              includeRequestSubCategories
              includeMarketSegments
              includeAccountGroupings
              includeAccounts
              includeOpportunities
              includeRequesters
              includeProjectManagers
              includePrimaryTeamMembers
              includeSubjectMatterExperts
              includeTeamMembers
              includeDepartments
              includeWrapUpCodes
              includeDirectIndirects
              includeCustomerTypes
              includeOnlyActive
              includeMembers
              includeOpportunityFlag
            />
          }
        >
          <WhenDesktop>
            <Space2>
              <Tooltip title='Re-load ticket list'>
                <StyledButton color={theme.textBase} onClick={handleRefreshTicketList} size='small'>
                  <Space2>
                    <MdRefresh />
                    Refresh
                  </Space2>
                </StyledButton>
              </Tooltip>
              <Tooltip title='Export is limited to max 1000 rows'>
                <StyledButton color={theme.textBase} onClick={handleExportTicketList} size='small'>
                  <Space2>
                    <FaFileCsv />
                    Export
                  </Space2>
                </StyledButton>
              </Tooltip>
              {getColumnsSelector}
              <TicketList2Grouping />
            </Space2>
          </WhenDesktop>
        </TitleBar>
        <div className='p-4 flex flex-col gap-4'>
          <TicketListControl2 allowSorting={true} getColumnsSelector={handleGetColumnsSelector} />
        </div>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// TicketList.propTypes = {};

export default TicketList2;
