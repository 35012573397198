import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRouteLayout from './PrivateRouteLayout';
import PrivateRoute from './PrivateRoute';
import Page403 from 'src/components/pages/Errors/Page403';
import Page404 from 'src/components/pages/Errors/Page404';
import Page410 from 'src/components/pages/Errors/Page410';
import SignIn from 'src/components/pages/SignIn/SignIn';
import SignInError from 'src/components/pages/SignIn/SignInError/SignInError';
import WelcomeToSpcPortal from 'src/components/pages/WelcomeToSpcPortal/WelcomeToSpcPortal';

// import Home from 'src/components/pages/Home/Home';
import TenantSetup from 'src/components/pages/TenantSetup/TenantSetup';
import Landing from 'src/components/pages/Landing/Landing';
import HomeMasterAdmin from 'src/components/pages/MasterAdmin/Home/Home';
import SecurityQAGPT from 'src/components/pages/SecurityQAGPT/SecurityQAGPT';
import UserProfile from 'src/components/pages/UserProfile/UserProfile';
import MasterAdminTenants from 'src/components/pages/MasterAdmin/Tenants/Tenants';
import MasterAdminUsers from 'src/components/pages/MasterAdmin/Users/Users';
import MasterAdminPermissions from 'src/components/pages/MasterAdmin/PermissionsAndRoles/PermissionsAndRoles';
import MasterAdminAiModels from 'src/components/pages/MasterAdmin/AiModels/AiModels';
import MasterAdminAiTemplates from 'src/components/pages/MasterAdmin/AiTemplates/AiTemplates';
import MasterAdminCopilot from 'src/components/pages/MasterAdmin/COEAIAssistant/COEAIAssistant';
import MasterAdminCopilotFeedback from 'src/components/pages/MasterAdmin/CopilotFeedback/CopilotFeedback';
import MasterAdminFSIReport from 'src/components/pages/MasterAdmin/FSIReport/FSIReport';
import MasterAdminTeacketServices from 'src/components/pages/MasterAdmin/TeacketServices/TeacketServices';
import MasterAdminInvitationLinks from 'src/components/pages/MasterAdmin/InvitationLinks/InvitationLinks';
import MasterAdminMessages from 'src/components/pages/MasterAdmin/AdminMessages/AdminMessages';
import Copilot from 'src/components/pages/Products/COEAIAssistant/COEAIAssistant';
import SecurityAdvisor from 'src/components/pages/Products/SecurityAdvisor/SecurityAdvisor';
import GreenTeacket from 'src/components/pages/Products/GreenTeacket/GreenTeacket';
import DataSubjectRequests from 'src/components/pages/Products/DataSubjectRequests/DataSubjectRequests';
import AuditViewerHome from 'src/components/pages/AuditViewer/Home';
import AuditViewerEvents from 'src/components/pages/AuditViewer/Events';
import AuditViewerEventsUninstall from 'src/components/pages/AuditViewer/EventsUninstall';
import VideosDocs from 'src/components/pages/Products/VideosDocs/VideosDocs';
import CustomerInsights from 'src/components/pages/Products/CustomerInsights/CustomerInsights';
import AuditViewer from 'src/components/pages/Products/AuditViewer/AuditViewer';
import SecurityAdvisorHome from 'src/components/pages/SecurityAdvisor/Home';
// import SecurityAdvisorEvents from 'src/components/pages/SecurityAdvisor/Events';
import SecurityAdvisorOptions from 'src/components/pages/SecurityAdvisor/Options';
// import SecurityAdvisorEventsUninstall from 'src/components/pages/SecurityAdvisor/EventsUninstall';
import DataSubjectRequestsHome from 'src/components/pages/DataSubjectRequests/Home';
import ReportsHandler from 'src/components/reports/ReportsHandler';
import Dashboards from 'src/components/pages/Dashboards/Home';
import DashboardFeaturesAccess from 'src/components/pages/Dashboards/FeaturesAccess';
import NewDataSubjectRequest from 'src/components/pages/DataSubjectRequests/Components/NewDataSubjectRequest';
import SecurityQaWidgetHome from 'src/components/pages/SecurityQaWidget/Home';
import ComplianceHome from 'src/components/pages/Compliance/Home';
import Videos from 'src/components/pages/Videos/Videos';
import TeacketHome from 'src/components/pages/Teacket/Home';
import TeacketTicketList from 'src/components/pages/Teacket/TicketList/TicketList';
import TeacketTicketList2 from 'src/components/pages/Teacket/TicketList/TicketList2';
import TeacketTicket from 'src/components/pages/Teacket/Ticket';
import TeacketTicketCreate from 'src/components/pages/Teacket/TicketCreate/TicketCreate';
import TeacketDashboard from 'src/components/pages/Teacket/Dashboard/Dashboard';
import TeacketDashboardTimeTracking from 'src/components/pages/Teacket/DashboardTimeTracking/DashboardTimeTracking';
import TeacketMyTickets from 'src/components/pages/Teacket/MyTickets/MyTickets';

// FSI Report
import FsiReportHome from 'src/components/pages/FsiReport/Home';
import FsiReportCreateNew from 'src/components/pages/FsiReport/CreateNew';
import FsiReportProjectPage from 'src/components/pages/FsiReport/ProjectPage';

// Download Sesciton
import MasterAdminDownloads from 'src/components/pages/MasterAdmin/Downloads/Downloads';

// GDPR
import GDPR from 'src/components/pages/MasterAdmin/GDPR/GDPR';

//#region "PROVIDERS"
import SecurityQaWidgetProvider from 'src/context/SecurityQaWidgetProvider';
import HomePageProvider from 'src/context/HomePageProvider';
import TeacketProvider from 'src/context/TeacketProvider';
import FsiReportProvider from 'src/context/FsiReportProvider';
import DashboardsProvider from 'src/context/DashboardsProvider';
//#endregion

import PageViewTracker from './PageViewTracker';
import Test from 'src/components/pages/Tests/Test';
import Groups from 'src/components/pages/MasterAdmin/Groups/Groups';
import GettingStarted from 'src/components/pages/Products/GettingStarted/GettingStarted';
import TeamDashboard from 'src/components/pages/Teacket/TeamDashboard/TeamDashboard';
import AdminDashboard from 'src/components/pages/Teacket/AdminDashboard/AdminDashboard';
import AdminRouting from 'src/components/pages/Teacket/Admin/Routing/AdminRouting';

const history = createBrowserHistory();

const Router = () => {
  // const getPrivateRoute = (path, element) => {
  //   return <Route path={path} element={<PrivateRoute>{element}</PrivateRoute>} />;
  // };

  const getPrivateRouteLayout = (path, element) => {
    return (
      <Route
        path={path}
        element={
          <PrivateRoute>
            <PrivateRouteLayout>{element}</PrivateRouteLayout>
          </PrivateRoute>
        }
      />
    );
  };

  return (
    <BrowserRouter history={history}>
      <PageViewTracker />
      <Routes>
        <Route index element={<Landing />} />
        {getPrivateRouteLayout('/test', <Test />)}
        <Route path='/signin' element={<SignIn />} />
        <Route path='/signinerror' element={<SignInError />} />
        <Route path='/welcome-to-spc-center/:id' element={<WelcomeToSpcPortal />} />
        <Route
          path='/security-qa-widget/:id'
          element={
            <SecurityQaWidgetProvider>
              <SecurityQaWidgetHome />
            </SecurityQaWidgetProvider>
          }
        />
        {/* {getPrivateRouteLayout('/home', <Home />)} */}
        {getPrivateRouteLayout(
          '/home',
          <HomePageProvider>
            <ComplianceHome />
          </HomePageProvider>
        )}
        {getPrivateRouteLayout(
          '/dashboards',
          <DashboardsProvider>
            <Dashboards />
          </DashboardsProvider>
        )}
        {getPrivateRouteLayout('/dashboards/features-access', <DashboardFeaturesAccess />)}
        {getPrivateRouteLayout('/dashboards/copilot-feedback', <MasterAdminCopilotFeedback />)}
        {getPrivateRouteLayout('/home/my-profile', <UserProfile />)}
        {getPrivateRouteLayout('/COEAIAssistant', <SecurityQAGPT />)}
        {getPrivateRouteLayout('/COEAIAssistant/thin', <SecurityQAGPT />)}
        {getPrivateRouteLayout('/master-admin', <HomeMasterAdmin />)}
        {getPrivateRouteLayout('/master-admin/tenants', <MasterAdminTenants />)}
        {getPrivateRouteLayout('/master-admin/users', <MasterAdminUsers />)}
        {getPrivateRouteLayout('/master-admin/roles-permissions', <MasterAdminPermissions />)}
        {getPrivateRouteLayout('/master-admin/aimodels', <MasterAdminAiModels />)}
        {getPrivateRouteLayout('/master-admin/aitemplates', <MasterAdminAiTemplates />)}
        {getPrivateRouteLayout('/master-admin/COEAIAssistant', <MasterAdminCopilot />)}
        {getPrivateRouteLayout('/master-admin/fsi-report', <MasterAdminFSIReport />)}
        {getPrivateRouteLayout('/master-admin/teacket-services', <MasterAdminTeacketServices />)}
        {getPrivateRouteLayout('/master-admin/downloads', <MasterAdminDownloads />)}
        {getPrivateRouteLayout('/master-admin/invitation-links', <MasterAdminInvitationLinks />)}
        {getPrivateRouteLayout('/master-admin/admin-messages', <MasterAdminMessages />)}
        {getPrivateRouteLayout('/master-admin/gdpr', <GDPR />)}
        {getPrivateRouteLayout('/master-admin/groups', <Groups />)}
        {getPrivateRouteLayout('/security-advisor', <SecurityAdvisorHome />)}
        {getPrivateRouteLayout('/security-advisor/thin', <SecurityAdvisorHome />)}
        {getPrivateRouteLayout('/security-advisor/options', <SecurityAdvisorOptions />)}
        {getPrivateRouteLayout('/data-subject-requests', <DataSubjectRequestsHome />)}
        {getPrivateRouteLayout('/data-subject-requests/thin', <DataSubjectRequestsHome />)}
        {getPrivateRouteLayout('/data-subject-requests/new', <NewDataSubjectRequest />)}
        {getPrivateRouteLayout('/audit-viewer', <AuditViewerHome />)}
        {getPrivateRouteLayout('/audit-viewer/thin', <AuditViewerHome />)}
        {getPrivateRouteLayout('/audit-viewer/events', <AuditViewerEvents />)}
        {getPrivateRouteLayout('/audit-viewer/events/uninstall', <AuditViewerEventsUninstall />)}
        {getPrivateRouteLayout('/videos', <Videos />)}
        {/* <TEACKET> */}
        {getPrivateRouteLayout(
          '/teacket',
          <TeacketProvider>
            <TeacketHome />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/tickets',
          <TeacketProvider>
            <TeacketTicketList />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/tickets2',
          <TeacketProvider>
            <TeacketTicketList2 />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/tickets/:ticketId',
          <TeacketProvider>
            <TeacketTicket />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/create-new',
          <TeacketProvider>
            <TeacketTicketCreate />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/dashboards/general',
          <TeacketProvider>
            <TeacketDashboard />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/dashboards/time-tracking',
          <TeacketProvider>
            <TeacketDashboardTimeTracking />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/dashboards/team',
          <TeacketProvider>
            <TeamDashboard />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/dashboards/admin',
          <TeacketProvider>
            <AdminDashboard />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/my-tickets',
          <TeacketProvider>
            <TeacketMyTickets />
          </TeacketProvider>
        )}
        {getPrivateRouteLayout(
          '/teacket/admin/routing',
          <TeacketProvider>
            <AdminRouting />
          </TeacketProvider>
        )}
        {/* </TEACKET> */}
        {/* <FSI REPORT> */}
        {getPrivateRouteLayout(
          '/fsi-report',
          <FsiReportProvider>
            <FsiReportHome />
          </FsiReportProvider>
        )}
        {getPrivateRouteLayout(
          '/fsi-report/new-project',
          <FsiReportProvider>
            <FsiReportCreateNew />
          </FsiReportProvider>
        )}
        {getPrivateRouteLayout(
          '/fsi-report/projects/:projectId',
          <FsiReportProvider>
            <FsiReportProjectPage />
          </FsiReportProvider>
        )}
        {/* </FSI REPORT> */}
        <Route path='/reports/:reportId/:gcEnv/:gcToken' element={<ReportsHandler />} />
        {/* HELP PAGES */}
        <Route path='/products/getting-started' element={<GettingStarted />} />
        <Route path='/products/COEAIAssistant' element={<Copilot />} />
        <Route path='/products/security-advisor' element={<SecurityAdvisor />} />
        <Route path='/products/green-teacket' element={<GreenTeacket />} />
        <Route path='/products/data-subject-requests' element={<DataSubjectRequests />} />
        <Route path='/products/audit-viewer' element={<AuditViewer />} />
        <Route path='/products/videos-docs' element={<VideosDocs />} />
        <Route path='/products/customer-insights' element={<CustomerInsights />} />

        <Route path='/tenant-setup/:tenantId' element={<TenantSetup />} />

        <Route path='/403' element={<Page403 />} />
        <Route path='/410' element={<Page410 />} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
