import React from 'react';
import PropTypes from 'prop-types';
import { Input, Space } from 'antd';

const FieldStringArray = ({ propName, fieldDef, defaultValue, onChange }) => {
  //console.log('defaultValue', propName, defaultValue);
  return (
    <div>
      <Space>
        {fieldDef.description}
        {fieldDef?.details && <span className='text-xs opacity-60 ml-2'>{fieldDef?.details}</span>}
      </Space>
      <div>
        <Input
          defaultValue={defaultValue?.join(',') ?? ''}
          onChange={(e) => {
            if (!onChange) return;
            onChange({ propName, value: e.target.value?.split(',') });
          }}
          placeholder='Enter a value...'
        />
      </div>
    </div>
  );
};

FieldStringArray.propTypes = {
  propName: PropTypes.string,
  fieldDef: PropTypes.object,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default FieldStringArray;
