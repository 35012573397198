import React, { useContext, useState, useEffect } from 'react';
import UserSelector from 'src/components/controls/UserSelector/UserSelector';
import PropertyItem from 'src/components/layout/PropertyItem';
import TeacketContext from 'src/context/TeacketContext';
import { getDistinct3, notEmptyArray, sleepMs } from 'src/misc/Misc';
import useUserInfo from 'src/hooks/useUserInfo';
import { Button, message, Skeleton } from 'antd';
import TicketTimeTrackingHoursSelector from './TicketTimeTrackingHoursSelector';
import Space2 from 'src/components/layout/Space2';
import { FaRegClock, FaPlus } from 'react-icons/fa6';
import { IoMdWarning } from 'react-icons/io';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { postTicketTimeTracking, getTicketTimeTrackingDate } from 'src/api/teacket';
import { uuid } from 'short-uuid';
import Smile from 'src/components/layout/Smile';
import dayjs from 'dayjs';
import { TIME_TRACKING_HOURS_PER_DAY } from '../../Misc/misc';

//import PropTypes from 'prop-types';

const TicketTimeTrackingSubmit = () => {
  const { currentTicket, currentTicketMembers, getCurrentTicketService, setCurrentTicketTimeTrackingForceReload, determineIsWidgetReadOnly, getServiceSettingForCurrentTicket } = useContext(TeacketContext);
  const { id: userId } = useUserInfo();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [timeTrackingUsers, setTimeTrackingUsers] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedHours, setSelectedHours] = useState(1);
  const [selectedDay, setSelectedDay] = useState(dayjs().utc(true).startOf('day'));
  const [showSmileIcon, setShowSmileIcon] = useState(false);
  const [loadingReportedDate, setLoadingReportedDate] = useState(false);
  const [reportedDate, setReportDate] = useState(null);

  const [timeTrackingHoursPerDay] = useState(parseInt(getServiceSettingForCurrentTicket('TimeTrackingHoursPerDay') ?? TIME_TRACKING_HOURS_PER_DAY));

  useEffect(() => {
    getTimeTrackingUsers();
  }, [currentTicketMembers]);

  useEffect(() => {
    if (!selectedDay) return;
    if (!selectedUserId) return;
    if (!currentTicket?.id) return;
    loadReportedDate();
  }, [selectedDay, selectedUserId, currentTicket]);

  const getTimeTrackingUsers = () => {
    const { props: props1 } = getCurrentTicketService();
    const roleList = props1?.roleList ?? [];
    const result = notEmptyArray(currentTicketMembers)
      ? getDistinct3(
          currentTicketMembers
            .filter((member) =>
              roleList
                .filter((role) => !role.excludeFromTimeTracking)
                .map((role) => role.role)
                .includes(member.role)
            )
            .map((member) => {
              return { id: member.user_id, name: member.user_name, image: member.user_pic };
            }),
          'id'
        )
      : null;
    setTimeTrackingUsers(result);
    setSelectedUserId(notEmptyArray(result) && result.some((u) => u.id === userId) ? userId : null);
  };

  const handleUserSelectorOnSelect = (id) => {
    //console.log('handleUserSelectorOnSelect:', id);
    setSelectedUserId(id);
  };

  const handleHoursSelectorHoursOnSelect = (hours) => {
    //console.log('handleHoursSelectorHoursOnSelect:', hours);
    setSelectedHours(hours);
  };

  const handleHoursSelectorDayOnSelect = (day) => {
    console.log('handleHoursSelectorDayOnSelect:', day);
    setSelectedDay(day);
  };

  const loadReportedDate = async () => {
    console.log('loadReportedDate:', currentTicket?.id, selectedUserId, selectedDay);
    setLoadingReportedDate(true);
    setReportDate(null);
    try {
      const resp = await getTicketTimeTrackingDate(currentTicket.id, selectedUserId, selectedDay);
      setReportDate(resp);
    } catch (error) {
      console.log(error);
    }
    setLoadingReportedDate(false);
  };

  const handleSubmit = () => {
    console.log('handleSubmit:', selectedUserId, selectedHours);

    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Submitting hours...',
          duration: 0,
        });
        await postTicketTimeTracking(currentTicket.id, selectedUserId, selectedHours, selectedDay.format());
        setSelectedHours(1);
        setSelectedDay(null);
        messageApi.open({
          key,
          type: 'success',
          content: 'Hours submitted',
          duration: 3,
        });
        triggetShowingSmileIcon();
        setCurrentTicketTimeTrackingForceReload(true);
      } catch (error) {
        console.log(error);
        messageApi.destroy(key);
      }
    });
  };

  const triggetShowingSmileIcon = async () => {
    setShowSmileIcon(true);
    await sleepMs(1500);
    setShowSmileIcon(false);
  };

  const canSubmitMore = () => {
    console.log('canPostMore()');
    if (!timeTrackingHoursPerDay) return false;
    if (loadingReportedDate) return false;
    if (!selectedUserId) return false;
    if (!selectedHours) return false;
    if (!selectedDay) return false;
    if ((reportedDate?.hours ?? 0) + selectedHours > timeTrackingHoursPerDay) return false;
    return true;
  };

  const isLimitReached = () => (reportedDate?.hours ?? 0) + selectedHours > timeTrackingHoursPerDay;

  return (
    <div className={`w-full flex flex-col gap-2 ${true === determineIsWidgetReadOnly('Time Tracking') ? 'disabled' : null}`}>
      {contextHolder}
      <PropertyItem
        label='Who?'
        value={
          <div className='my-2'>
            <UserSelector userList={timeTrackingUsers} onSelect={handleUserSelectorOnSelect} value={selectedUserId} />
          </div>
        }
      />

      <div className='my-2'>
        <TicketTimeTrackingHoursSelector onHoursSelect={handleHoursSelectorHoursOnSelect} selectedHours={selectedHours} onDaySelect={handleHoursSelectorDayOnSelect} selectedDay={selectedDay} />
      </div>

      <div className='flex flex-row gap-1 justify-between'>
        <Space2 className={`overflow-hidden ${reportedDate ? (true === isLimitReached() ? 'text-red-600 dark:text-red-400' : 'text-orange-600 dark:text-orange-300') : null} ${loadingReportedDate ? 'animate-pulse' : null}`}>
          {selectedHours && selectedUserId && selectedDay && !loadingReportedDate ? (
            <>
              <div className='text-xl'>{reportedDate ? <IoMdWarning /> : <FaRegClock />}</div>
              <div style={{ lineHeight: '1.1rem' }} className='text-sm'>
                {reportedDate ? (
                  <div>
                    <span className='font-light opacity-80' style={{ fontStretch: 'condensed' }}>
                      Already reported
                    </span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {reportedDate.hours} {reportedDate.hours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                ) : (
                  <div>
                    <span className='font-light opacity-80'>Submit</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedHours} {selectedHours >= 2 ? 'hours' : 'hour'}
                    </span>{' '}
                    <span className='font-light opacity-80'>on</span>{' '}
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedDay.format('MMM DD')}
                    </span>{' '}
                  </div>
                )}
                <div>
                  <span className='font-light opacity-80'>for</span>{' '}
                  <span className='font-bold' style={{ fontStretch: 'condensed', overflow: 'hidden' }}>
                    {timeTrackingUsers.find((x) => x.id === selectedUserId).name}
                  </span>
                </div>
                {true === isLimitReached() && (
                  <div className='font-light opacity-80' style={{ fontStretch: 'condensed' }}>
                    Daily limit is {timeTrackingHoursPerDay} hrs
                  </div>
                )}
              </div>
            </>
          ) : (
            <Skeleton.Input style={{ width: 200 }} />
          )}
        </Space2>

        <div className='relative'>
          <div className='absolute z-50 flex flex-row justify-center mt-[-37px] w-full'>
            <Smile show={showSmileIcon} label='Thank you!' />
          </div>

          <Button type='primary' className={'px-2'} onClick={handleSubmit} disabled={false === canSubmitMore()}>
            {selectedHours && selectedUserId && selectedDay ? (
              <Space2>
                <FaPlus />
                <div style={{ lineHeight: '1.1rem' }} className='text-sm'>
                  <div>
                    <span className='font-bold' style={{ fontStretch: 'condensed' }}>
                      {selectedHours} {selectedHours >= 2 ? 'hours' : 'hour'}
                    </span>
                  </div>
                </div>
              </Space2>
            ) : (
              <span className='text-sm font-light opacity-80'>Submit</span>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

TicketTimeTrackingSubmit.propTypes = {};

export default TicketTimeTrackingSubmit;
