import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'antd';
import ServicesUsersPicker from '../../../Components/ServicesUsersPicker';
import { notArrayOrEmpty, textSorterStrArray } from 'src/misc/Misc';

const DashboardFiltersUsersSelector = ({ serviceIdList, roleList, onApply, onCancel }) => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState(null);
  const [clearSelections, setClearSelections] = useState(null);

  useEffect(() => {
    if (!clearSelections) return;
    setClearSelections(null);
  }, [clearSelections]);

  const handleApply = () => {
    setClearSelections(true);
    if (onApply)
      onApply({
        selectedRole,
        selectedMembers: selectedMembers?.map((x) => {
          return {
            user_id: x.value,
            user_name: x.label,
            role: selectedRole,
          };
        }),
      });
  };

  const handleCancel = () => {
    setClearSelections(true);
    if (onCancel) onCancel();
  };

  const handleRoleOnChange = (v) => {
    console.log('handleRoleOnChange()', v);
    setSelectedRole(v);
  };

  const handleUsersOnPick = (v) => {
    console.log('handleUsersOnPick()', v);
    setSelectedMembers(v);
  };

  const getOptions = () => {
    let items = roleList
      ?.sort((a, b) => textSorterStrArray(a, b))
      .map((x) => {
        return { value: x, label: x };
      });
    items.unshift({ value: '[Any role]', label: '[Any role]' });
    return items;
  };

  if (clearSelections) return;
  return (
    <div className='w-96 flex flex-col gap-2'>
      <div>
        <div className='text-xs'>Role</div>
        <Select allowClear showSearch placeholder='Select a role...' className='w-full' options={getOptions()} size='small' onChange={handleRoleOnChange} />
      </div>
      <div>
        <div className='text-xs'>Members</div>
        <ServicesUsersPicker serviceList={serviceIdList} width='100%' size='small' placeholder='Type to find a member...' onPick={handleUsersOnPick} />
      </div>
      <div className='mt-2 flex flex-row gap-2 justify-end'>
        <Button type='primary' size='small' onClick={handleApply} disabled={!selectedRole || true === notArrayOrEmpty(selectedMembers)}>
          Add
        </Button>
        <Button type='default' size='small' onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

DashboardFiltersUsersSelector.propTypes = {
  serviceIdList: PropTypes.array,
  roleList: PropTypes.array,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DashboardFiltersUsersSelector;
