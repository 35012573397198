import React, { useEffect, useState } from 'react';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../Misc/misc';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import TicketCreateControl from './Components/TicketCreateControl';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import DraggableLayoutProvider from 'src/components/layout/DraggableLayoutProvider';
import DraggableLayoutItem from 'src/components/layout/DraggableLayoutItem';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

//import PropTypes from 'prop-types';

const TicketCreate = () => {
  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  const componentsGallery = [
    {
      id: 'Submission',
      component: (
        <DraggableLayoutItem title='Create a New Ticket' centered={true}>
          <TicketCreateControl />
        </DraggableLayoutItem>
      ),
      col: 1,
    },
  ];

  const [layoutItemsSelector, setLayoutItemsSelector] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Create new',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.ticket.create']}>
      <TitleBar
        title={
          <Space className='btn'>
            <BsCupHotFill />
            <div>
              <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
            </div>
          </Space>
        }
        afterTitleExtras={
          <div>
            <PageSwitch pageList={pageList} />
          </div>
        }>
        {layoutItemsSelector}
      </TitleBar>
      <LoaderServicesForTenant>
        <div className='p-4'>
          <DraggableLayoutProvider getLayoutItemsSelector={(layoutItemsSelector) => setLayoutItemsSelector(layoutItemsSelector)} columns={3} mainColumnIndex={1} componentsGallery={componentsGallery} layoutKey={LOCAL_STORAGE_KEYS.layoutTeacketTicketCreate} layoutHiddenKey={LOCAL_STORAGE_KEYS.layoutTeacketTicketCreateHidden} />
        </div>
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

// NewTicket.propTypes = {};

export default TicketCreate;
