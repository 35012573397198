import React, { useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import useBreadcrumb from 'src/hooks/useBreadcrumb';
import TitleBar from 'src/components/layout/TitleBar';
import { Space } from 'antd';
import { BsCupHotFill } from 'react-icons/bs';
import PageSwitch from 'src/components/layout/PageSwitch';
import { pageList } from '../Misc/misc';
import PermissionsProvider from 'src/providers/PermissionsProvider';
import LoaderServicesForTenant from '../Loaders/LoaderServicesForTenant';
import DashboardData from './components/DashboardData';
import DashboardFilters from './components/DashboardFilters/DashboardFilters';

//import PropTypes from 'prop-types';

const Dashboard = () => {
  const { dashboardLayoutItemsSelector } = useContext(TeacketContext);

  const { setBreadcrumb, dropBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumb([
      {
        title: 'Green Teacket',
        path: '/teacket',
      },
      {
        title: 'Dashboards',
      },
      {
        title: 'Tickets',
      },
    ]);

    return () => {
      dropBreadcrumb();
    };
  }, []);

  return (
    <PermissionsProvider requiredPermissions={['apps.teacket.dashboards.tickets.view']}>
      <LoaderServicesForTenant>
        <TitleBar
          title={
            <Space className='btn'>
              <BsCupHotFill />
              <div>
                <span className='text-green-800 dark:text-green-200'>Green Tea</span>cket
              </div>
            </Space>
          }
          afterTitleExtras={
            <div>
              <PageSwitch pageList={pageList} />
            </div>
          }
          bottomExtras={
            <DashboardFilters
              id='tickets-dashboard'
              collapsible={false}
              includeRegions
              includeSubRegions
              includePlatforms
              includeStages
              includeTicketTypes
              includeRequestStatuses
              includeRequestCategories
              includeRequestSubCategories
              includeMarketSegments
              includeAccountGroupings
              includeAccounts
              includeOpportunities
              includeRequesters
              includeProjectManagers
              includePrimaryTeamMembers
              includeSubjectMatterExperts
              includeTeamMembers
              includeDepartments
              includeWrapUpCodes
              includeDirectIndirects
              includeCustomerTypes
              includeRegionalCareOffers
            />
          }
          defaultExpanded={true}
        >
          {dashboardLayoutItemsSelector}
        </TitleBar>

        <DashboardData />
      </LoaderServicesForTenant>
    </PermissionsProvider>
  );
};

export default Dashboard;
