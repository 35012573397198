import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Space } from 'antd';

const FieldNumber = ({ propName, fieldDef, defaultValue, onChange }) => {
  return (
    <div>
      <Space>
        {fieldDef.description}
        {fieldDef?.details && <span className='text-xs opacity-60 ml-2'>{fieldDef?.details}</span>}
      </Space>
      <div>
        <InputNumber
          className='w-full'
          defaultValue={defaultValue}
          onChange={(v) => {
            if (!onChange) return;
            onChange({ propName, value: v });
          }}
          placeholder='Enter a value...'
        />
      </div>
    </div>
  );
};

FieldNumber.propTypes = {
  propName: PropTypes.string,
  fieldDef: PropTypes.object,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default FieldNumber;
