import React, { useContext, useState } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { getTicketAISummary } from 'src/api/teacket';
import { Tooltip, Button, message, Modal, Input, Tag, Space, Spin } from 'antd';
import { ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { postTicketShare } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { TbMailShare } from 'react-icons/tb';
import { uuid } from 'short-uuid';
import useUserInfo from 'src/hooks/useUserInfo';
import { hasUserPermission } from 'src/misc/Session';

const { TextArea } = Input;

const TicketShare = () => {
  const { permissions: userPermissions } = useUserInfo();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailTo, setEmailTo] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailTags, setEmailTags] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { currentTicket } = useContext(TeacketContext);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const loadModal = async () => {
    console.log('loadModal()');
    try {
      setEmailTags([]);
      setEmailBody('');
      setEmailTo('');
      setIsModalVisible(true);
      setLoading(true);
      const resp = await getTicketAISummary(currentTicket.id);
      setEmailBody(resp?.summary);
    } catch (error) {
      console.error('loadModal() -> error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOk = async () => {
    const key = uuid();

    await executeAsyncProcess(async () => {
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Share ticket details...',
          duration: 3,
        });
        setSending(true);
        await postTicketShare(currentTicket.id, { to: emailTags, summary: emailBody });
        messageApi.success({ content: 'Ticket details shared!', key, duration: 3 });
      } catch (error) {
        console.error(error);
        messageApi.error({ content: 'Failed to share ticket details!', key, duration: 3 });
      } finally {
        setSending(false);
      }
    });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailTo(email);
    setIsEmailValid(emailRegex.test(email));
  };

  const handleEmailPressEnter = () => {
    if (isEmailValid && emailTo && !emailTags.includes(emailTo)) {
      setEmailTags([...emailTags, emailTo]);
      setEmailTo('');
    }
  };

  const handleTagClose = (removedTag) => {
    setEmailTags(emailTags.filter((tag) => tag !== removedTag));
  };
  return (
    <>
      {contextHolder}
      <Modal
        open={isModalVisible}
        title='Share ticket details'
        forceRender
        onCancel={handleCancel}
        footer={
          <div key={1} className='flex flex-auto'>
            <div className='flex flex-auto text-left w-full'></div>
            <div className='flex flex-auto text-right '>
              <Button key='cancel' onClick={handleCancel}>
                Cancel
              </Button>
              <Button disabled={loading || emailTags.length === 0 || sending} key='submit' type='primary' className='ml-2' onClick={handleOk}>
                <div className='flex flex-auto gap-1'>
                  <TbMailShare className='mt-1' />
                  Submit
                </div>
              </Button>
            </div>
          </div>
        }>
        <div className='flex flex-col space-y-4'>
          <Space>
            <Input
              addonAfter={
                (!isEmailValid && (
                  <Tooltip title='Invalid email address'>
                    <ExclamationCircleOutlined style={{ color: 'orange' }} />
                  </Tooltip>
                )) || <CheckOutlined />
              }
              type='text'
              placeholder='To'
              value={emailTo}
              onChange={handleEmailChange}
              onPressEnter={handleEmailPressEnter}
              style={{ minWidth: '250px', width: 'auto', display: 'inline-block', marginLeft: '8px', marginBottom: '4px' }}
            />

            <p className='text-xs opacity-60 ml-2'>Provide email addresses where you want to share this content with</p>
          </Space>

          <div>
            <div className='flex flex-row space-x-1 text-xs opacity-60'>
              <div className='font-semibold' style={{ minWidth: '70px' }}>
                Emails:
              </div>
              <div>
                {emailTags.map((tag) => (
                  <Tag key={tag} closable onClose={() => handleTagClose(tag)}>
                    {tag}
                  </Tag>
                ))}
              </div>
            </div>
          </div>

          <div className='text-xs opacity-60'>
            <div className='flex flex-row space-x-1'>
              <div className='font-semibold' style={{ minWidth: '70px' }}>
                Title:
              </div>
              <div>{currentTicket?.title}</div>
            </div>
            <div className='flex flex-row space-x-1 mt-2'>
              <div className='font-semibold' style={{ minWidth: '70px' }}>
                Details:
              </div>
              <div>{currentTicket?.content}</div>
            </div>
          </div>
          {loading ? (
            <div className='flex flex-col items-center justify-center space-y-2 font-xs opacity-60'>
              <Spin />
              <div className='mt-2 animate-pulse pb-4'>summarizing the ticket...</div>
            </div>
          ) : (
            <TextArea disabled={loading || sending} value={emailBody} onChange={(e) => setEmailBody(e.target.value)} rows={10} />
          )}
        </div>
      </Modal>
      {true === hasUserPermission('apps.teacket.ticket.share', userPermissions) && currentTicket && (
        <Tooltip title='Share via Email' placement='left'>
          <Button type='text' onClick={() => loadModal()}>
            <TbMailShare />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default TicketShare;
