import React, { useContext, useState } from 'react';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import SecurityAdvisorContext from 'src/context/SecurityAdvisorContext';
import { Checkbox, message, Space, Modal, Button, Card } from 'antd';
import { patchJob } from 'src/api/securityAdvisor';
import { MdCalendarToday, MdDelete } from 'react-icons/md';
import { Cron } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import { deleteJob } from 'src/api/securityAdvisor';
import cronstrue from 'cronstrue';
import YesNoDialog from 'src/components/controls/YesNoDialog/YesNoDialog';
import { uuid } from 'short-uuid';

const OptionsWidget = () => {
  const { executeAsyncProcess } = useAsyncProcesses();
  const { job, updateJob, setJob } = useContext(SecurityAdvisorContext);
  const [messageApi, contextHolder] = message.useMessage();
  // const { executeAsyncProcess } = useAsyncProcesses();

  const [cronModalOpen, setCronModalOpen] = useState(false);
  const [editedCron, setEditedCron] = useState(null);

  if (!job) return null;

  const hamdleUpdateHipaa = async (e) => {
    await executeAsyncProcess(async () => {
      try {
        const changes = { hipaa_required: e.target.checked };
        updateJob(changes);
        const result = await patchJob(job.id, changes);
        updateJob({ hipaa_required: result.hipaa_required });

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Job updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const hamdleUpdatePci = async (e) => {
    await executeAsyncProcess(async () => {
      try {
        const changes = { pci_required: e.target.checked };
        updateJob(changes);
        const result = await patchJob(job.id, changes);
        updateJob({ pci_required: result.pci_required });
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Job updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const hamdleUpdateIsEnabled = async (e) => {
    await executeAsyncProcess(async () => {
      try {
        const changes = { is_enabled: e.target.checked };
        updateJob(changes);
        const result = await patchJob(job.id, changes);
        updateJob({ is_enabled: result.is_enabled });
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Job updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleCronUpdate = async () => {
    await executeAsyncProcess(async () => {
      try {
        const changes = { cron: editedCron };
        updateJob(changes);
        setCronModalOpen(false);
        const result = await patchJob(job.id, changes);
        updateJob({ cron: result.cron });
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Job updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleCronCancel = () => {
    setCronModalOpen(false);
  };

  const handleDeleteJob = () =>
    new Promise(async (resolve) => {
      try {
        await deleteJob(job.id);
        setJob(null);
        window.location.reload();
        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Job deleted',
          duration: 3,
        });
      } catch (error) {
        console.error(error);
      } finally {
        resolve(null);
      }
    });

  return (
    <div>
      {/* <> */}
      {contextHolder}
      <Modal
        title='Edit Scheduler'
        closable={false}
        open={cronModalOpen}
        footer={[
          <Button key='btnYes' type='primary' className='btn-label' onClick={handleCronUpdate}>
            Update
          </Button>,
          <Button key='btnNo' className='btn-label' onClick={handleCronCancel}>
            Cancel
          </Button>,
        ]}>
        <Cron
          value={editedCron}
          setValue={(e) => {
            if (e === editedCron) return;
            setEditedCron(e);
          }}
          allowedPeriods={['month', 'week', 'day']}
          mode='single'
          clearButtonAction='empty'
        />
      </Modal>

      <div className={'text-center'}>
        <div className='flex flex-row flex-wrap gap-4 justify-center'>
          <Card title='Compliance' size='small'>
            <div className='flex flex-col gap-2'>
              <Checkbox checked={job.hipaa_required} onChange={hamdleUpdateHipaa}>
                HIPAA
              </Checkbox>
              <Checkbox checked={job.pci_required} onChange={hamdleUpdatePci}>
                PCI
              </Checkbox>
            </div>
          </Card>
          <Card title='Scheduler' size='small'>
            <div>
              <Space className='btn'>
                {job?.cron ? cronstrue.toString(job.cron) : 'Not set'}
                <MdCalendarToday
                  className='cursor-pointer hover:scale-125'
                  onClick={() => {
                    setEditedCron(job.cron);
                    setCronModalOpen(true);
                  }}
                />
              </Space>
            </div>
            <div className='mt-4'>
              <Checkbox checked={job.is_enabled} onChange={hamdleUpdateIsEnabled}>
                Is Enabled
              </Checkbox>
            </div>
          </Card>
          <Card title='Misc' size='small'>
            <YesNoDialog
              showLock
              title={
                <Space className='btn'>
                  <MdDelete />
                  Delete job and history
                </Space>
              }
              onYesClick={() => {
                handleDeleteJob();
              }}
              showRed={true}
              iconYes={<MdDelete />}
              labelNo='Cancel'
              body={<>Permanently delete the job definition and all history items including all genrated reports? This action cannot be undone.</>}>
              <Button size='small' className='opacity-60 hover:opacity-100'>
                <Space className='btn'>
                  <MdDelete />
                  Delete
                </Space>
              </Button>
            </YesNoDialog>
          </Card>
        </div>
      </div>
    </div>
  );
};

OptionsWidget.propTypes = {};

export default OptionsWidget;
