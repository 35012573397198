import React, { useEffect, useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import PropTypes from 'prop-types';
import { getTickets2 } from 'src/api/teacket';
import CondensedTable from 'src/components/layout/CondensedTableLegacy';
import { Spin } from 'antd';

const TicketListWithGroupingItem = ({ group, columns }) => {
  const { ticketList2selectedFilters, ticketListQuery, ticketListGroupBy } = useContext(TeacketContext);
  const pageSizeOptions = [5, 25, 50]; //
  //const pageSizeOptions = [1, 2, 20]; // use for pagination tests

  const [accountTickets, setAccountTickets] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(parseInt(pageSizeOptions[0]));
  const [loading, setLoading] = useState(false);
  const [sorter, setSorter] = useState(null);

  let initialLoad = false;

  useEffect(() => {
    console.log('group:', group);
    initialLoad = true;
    loadData();
  }, [group]);

  useEffect(() => {
    if (initialLoad) {
      return;
    }
    // console.log('page:', page);
    loadData();
  }, [page]);

  useEffect(() => {
    if (initialLoad) {
      return;
    }
    // console.log('pageSize:', pageSize);
    setPage(1);
    loadData();
  }, [pageSize]);

  const getFilters = () => {
    switch (ticketListGroupBy) {
      case 'acc_id':
        return { ...ticketList2selectedFilters, accounts: [group] };
      case 'category':
        return { ...ticketList2selectedFilters, requestCategories: [group] };
      case 'status':
        return { ...ticketList2selectedFilters, requestStatuses: [group] };
      default:
        throw new Error('Invalid ticketListGroupBy');
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const resp = await getTickets2(page, pageSize, sorter?.field, sorter?.order, ticketListQuery, getFilters());
      setAccountTickets(resp);
      initialLoad = false;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log('Sorter:', sorter);
    console.log('Sorter field:', sorter?.field);
    console.log('Sorter order:', sorter?.order);
  }, [sorter]);

  const renderShowTotal = (total, range) => (
    <div className='inline-flex items-center text-sm dark:text-gray-100 dark:bg-gray-800 text-gray-600 bg-gray-50 px-2 py-1 rounded-md'>
      <span>Showing&nbsp;</span>
      <span>
        {range[0]}-{range[1]}
      </span>
      <span className='mx-1'>of</span>
      <span className='font-medium dark:text-blue-400 text-blue-600'>{total}</span>
      <span className='ml-1'>{total > 1 ? 'tickets' : 'ticket'}</span>
    </div>
  );

  useEffect(() => {
    loadData();
  }, [sorter]);

  const handleOnSorterChange = (_, __, sorter) => {
    setSorter(sorter);
  };

  return (
    <>
      {loading ? (
        <>
          <Spin size='large' className='mt-2 mb-4 flex justify-center' />
        </>
      ) : (
        <>
          <CondensedTable
            pagination={{
              showSizeChanger: true,
              size: 'small',
              current: page,
              pageSize,
              total: accountTickets?.total || 0,
              showTotal: renderShowTotal,
              onChange: (page) => {
                setPage(page);
              },
              pageSizeOptions,
              onShowSizeChange: (current, size) => {
                // console.log('onShowSizeChange', current, size);
                setPage(1);
                setPageSize(size);
              },
            }}
            dataSource={accountTickets?.entities ?? []}
            columns={columns}
            onChange={handleOnSorterChange}
          />
        </>
      )}
    </>
  );
};

TicketListWithGroupingItem.propTypes = {
  group: PropTypes.string,
  columns: PropTypes.array,
};

export default TicketListWithGroupingItem;
