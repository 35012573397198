export const themeLight = {
  primary: '#3b82f6',
  textBase: '#152550',
  textLight: '#1b55da',
  light: '#eff7ff',
  backgroundBase: '#FFFFFF',
  backgroundLight: '#f0f6fe',
  backgroundMedium: '#dcebfd',
  backgroundTag: '#f0f0f0',
  backgroundDisabled: '#f5f5f5',
  red: '#EF4444', // red-500
  green: '#84CC16', // lime-500
  highlightStyle: { backgroundColor: '#075985', color: '#f3f6fc', fontWeight: '600' },
  borderLight: '#d9d9d9',
};

export const themeDark = {
  primary: '#3b82f6',
  textBase: '#f3f6fc',
  textLight: '#bae6fd',
  light: '#1e3a8a',
  backgroundBase: '#141414',
  backgroundLight: '#1e3a8a',
  backgroundMedium: '#2243A2',
  backgroundTag: '#313131',
  backgroundDisabled: '#262626',
  backgroundDarkItem: '#152550',
  backgroundDarkSumbMenuItem: '#152550',
  red: '#991B1B', //red-800
  green: '#365314', //lime-800
  highlightStyle: { backgroundColor: '#e0f2fe', color: '#23395d', fontWeight: '600' },
  borderLight: '#424242',
};

/* <OLD THEME> */

// export const themeLight = {
//   primary: '#6366F1', // indigo-500
//   textBase: '#312E81', // indigo-900
//   textLight: '#4338ca', // indigo-700
//   light: '#EEF2FF', // indigo-50
//   backgroundBase: '#FFFFFF', // white
//   backgroundLight: '#EEF2FF', // indigo-50
//   backgroundMedium: '#e0e7ff', // indigo-100
// };

// export const themeDark = {
//   primary: '#6366F1',
//   textBase: '#EEF2FF',
//   textLight: '#a0a3ae',
//   light: '#312E81',
//   backgroundBase: '#141414',
//   backgroundLight: '#312E81',
//   backgroundMedium: '#3730a3', // indigo-800
// };

// </OLD THEME>;
