import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { getServiceProps } from 'src/api/teacket';
import { isRequestCancelled } from 'src/misc/Misc';

const CategoryPicker = ({ serviceId, defaultValue, onChange, allowClear = true, width = '100%', placeholder = '[ALL]', filterPermissions }) => {
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (options) return;
    loadData();
  }, []);

  useEffect(() => {
    if (!defaultValue) return;
    setSelected(defaultValue);
  }, [options]);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = (await getServiceProps(serviceId, 'categoryList'))
        .filter((x) => !filterPermissions || !x.requiredPermission || filterPermissions.includes(x.requiredPermission))
        ?.sort((a, b) => a.category.localeCompare(b.category))
        .map((x) => {
          return { value: x.category, label: x.category, items: x.items && x.items.length > 0 ? x.items?.map((i) => ({ value: i.subCategory, label: i.subCategory, requiredPermission: i.requiredPermission })) : null };
        });

      const respFiltered = resp.map((x) => {
        if (x.items) {
          x.items = x.items.filter((i) => !filterPermissions || !i.requiredPermission || filterPermissions.includes(i.requiredPermission));
        }
        return x;
      });

      setOptions(respFiltered);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      setOptions([]);
      console.log(error);
    }
    setLoading(false);
  };

  const handleOnChange = (value) => {
    console.log('handleOnChange()', value, options);
    setSelected(value);
    if (!onChange) return;

    if (!value) {
      onChange(null);
      return;
    }

    const category = options.find((x) => x.value === value);
    onChange({
      category: category.value,
      subCategoryList: category.items,
    });
  };

  return <Select placeholder={placeholder} options={options} value={selected} style={{ width }} onChange={handleOnChange} loading={loading} allowClear={allowClear} showSearch />;
};

CategoryPicker.propTypes = {
  serviceId: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  allowClear: PropTypes.bool,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  filterPermissions: PropTypes.array,
};

export default CategoryPicker;
