import React, { useState, useEffect, useContext } from 'react';
import MasterAdminContext from 'src/context/MasterAdminContext';
import PropTypes from 'prop-types';
import TeacketServiceEditorRoutingEditor from './TeacketServiceEditorRoutingEditor';
import TeacketServiceEditorRoutingSelector from './TeacketServiceEditorRoutingSelector';
import PropertySection from 'src/components/layout/PropertySection';
import useTheme from 'src/hooks/useTheme';
import { getAdminRoutings, postAdminRouting, deleteAdminRouting, patchAdminRouting, postAdminRoutingQuery } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import { message } from 'antd';
import { uuid } from 'short-uuid';

const TeacketServiceEditorRouting = ({ serviceId }) => {
  const { theme } = useTheme();
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();
  const { setGreenTeacketServiceRoutingQuery } = useContext(MasterAdminContext);

  const [routing, setRouting] = useState(null);

  useEffect(() => {
    loadData();
  }, [serviceId]);

  const loadData = async () => {
    executeAsyncProcess(async () => {
      try {
        const resp = await getAdminRoutings(serviceId);
        // console.log('resp', resp);
        setRouting(resp);
        setGreenTeacketServiceRoutingQuery(null);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleRoutingOnTest = (e) => {
    console.log('handleRoutingOnTest()', e);
    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Querying...',
          duration: 0,
        });
        const resp = await postAdminRoutingQuery(serviceId, e);
        console.log('resp', resp);
        messageApi.open({
          key,
          type: 'success',
          content: (
            <>
              Found a rule <span className='font-light opacity-60 text-[0.6rem]'>{resp.id}</span>
            </>
          ),
          duration: 3,
        });
        if (resp?.id) setGreenTeacketServiceRoutingQuery(resp.id);
      } catch (error) {
        console.log(error);
        messageApi.open({
          key,
          type: 'error',
          content: 'Rule not found',
          duration: 3,
        });
        setGreenTeacketServiceRoutingQuery(null);
      }
    });
  };

  const handleRoutingOnAdd = (e) => {
    console.log('handleRoutingOnAdd()', e);
    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Adding rule...',
          duration: 0,
        });
        const resp = await postAdminRouting(serviceId, e);
        //console.log('resp', resp);
        let r = [...routing];
        r.unshift(resp);
        setRouting(r);
        messageApi.open({
          key,
          type: 'success',
          content: 'Rule added',
          duration: 3,
        });
        if (resp?.id) setGreenTeacketServiceRoutingQuery(resp.id);
      } catch (error) {
        console.log(error);
        messageApi.open({
          key,
          type: 'error',
          content: error?.toString() ?? 'Error',
          duration: 3,
        });
      }
    });
  };

  const handleRoutingOnDelete = (id) => {
    console.log('handleRoutingOnAdd()', routing);
    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Removing rule...',
          duration: 0,
        });
        await deleteAdminRouting(id);
        let r = routing.filter((x) => x.id !== id);
        setRouting(r);
        messageApi.open({
          key,
          type: 'success',
          content: 'Rule removed',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
        messageApi.open({
          key,
          type: 'error',
          content: error?.toString() ?? 'Error',
          duration: 3,
        });
      }
    });
  };

  const handleToOnChange = (id, emailList) => {
    console.log('handleToOnChange()', id, emailList);
    executeAsyncProcess(async () => {
      const key = uuid();
      try {
        messageApi.open({
          key,
          type: 'loading',
          content: 'Updating rule...',
          duration: 0,
        });
        const changes = { to_list: emailList?.join('|') ?? '' };
        const resp = await patchAdminRouting(id, changes);

        let r = [...routing];
        let i = r.findIndex((x) => x.id === id);
        r[i] = resp;
        setRouting(r);
        messageApi.open({
          key,
          type: 'success',
          content: 'Rule updated',
          duration: 3,
        });
      } catch (error) {
        console.log(error);
        messageApi.open({
          key,
          type: 'error',
          content: error?.toString() ?? 'Error',
          duration: 3,
        });
      }
    });
  };

  const handleCcOnChange = (id, emailList) => {
    console.log('handleCcOnChange()', id, emailList);
    executeAsyncProcess(async () => {
      try {
        const changes = { cc_list: emailList?.join('|') ?? '' };
        const resp = await patchAdminRouting(id, changes);

        let r = [...routing];
        let i = r.findIndex((x) => x.id === id);
        r[i] = resp;
        setRouting(r);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleOnRefresh = () => {
    console.log('handleOnRefresh()');
    loadData();
  };

  return (
    <>
      {contextHolder}
      <div className='flex flex-col lg:flex-row w-full gap-4'>
        <div className='flex-1'>
          <TeacketServiceEditorRoutingSelector routing={routing} onDelete={handleRoutingOnDelete} toOnChange={handleToOnChange} ccOnChange={handleCcOnChange} onRefresh={handleOnRefresh} />
        </div>
        <div className='flex-0 w-full lg:w-64'>
          <PropertySection bgColor={theme.backgroundMedium}>
            <div className='my-2'>
              <TeacketServiceEditorRoutingEditor serviceId={serviceId} onAdd={handleRoutingOnAdd} onTest={handleRoutingOnTest} />
            </div>
          </PropertySection>
        </div>
      </div>
    </>
  );
};

TeacketServiceEditorRouting.propTypes = {
  serviceId: PropTypes.string,
};

export default TeacketServiceEditorRouting;
