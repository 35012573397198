import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { getUsers } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { MdOutlineEmail } from 'react-icons/md';
import { LuBuilding } from 'react-icons/lu';
import { isRequestCancelled } from 'src/misc/Misc';
import UserImage from 'src/components/layout/UserImage';
import { FaHashtag } from 'react-icons/fa';
import Space2 from 'src/components/layout/Space2';
//import PropTypes from 'prop-types';

const ServicesUsersPicker = ({ width = '240px', onPick, serviceList, size = null, placeholder = '[all users]' }) => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClear = async () => {
    console.log('handleClear');
    setUserList([]);
    if (onPick) onPick(null);
  };

  const handleSearchForTenants = async (v) => {
    setUserList([]);
    if (v.length < 3) return;
    try {
      setLoading(true);
      let resp = await getUsers(v, serviceList);
      // console.log('Tenants:', resp);
      if (resp) setUserList(resp);
      setLoading(false);
    } catch (error) {
      if (true === isRequestCancelled(error)) return;
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChange = (_, o) => {
    console.log('UserPicker onSelect:', o);
    if (onPick) onPick(o);
  };

  return (
    <Select
      size={size}
      mode='multiple'
      showSearch
      allowClear
      placeholder={placeholder}
      notFoundContent={loading ? <Spin size='small' style={{ margin: '4px 8px' }} /> : null}
      filterOption={false}
      onSearch={handleSearchForTenants}
      onClear={handleClear}
      onChange={handleOnChange}
      style={{ width }}
      autoComplete='off'
      optionRender={(option) => {
        const user = userList.find((x) => x.user_id === option.key);
        if (!user) return null;
        return (
          <div className='flex flex-row gap-2 my-1'>
            <div>
              <UserImage image={user.user_pic} size={24} />
            </div>
            <div className='flex flex-col'>
              <div className='font-bold'>{user.user_name}</div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space2>
                  <FaHashtag />
                  {user.user_id}
                </Space2>
              </div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space2>
                  <MdOutlineEmail />
                  {user.user_email}
                </Space2>
              </div>
              <div className='text-xs font-light opacity-60 overflow-hidden'>
                <Space2>
                  <LuBuilding />
                  {user.tenant_name}
                </Space2>
              </div>
            </div>
          </div>
        );
      }}
      options={userList.map((d) => {
        return { value: d.user_id, label: d.user_name };
      })}
    />
  );
};

ServicesUsersPicker.propTypes = {
  serviceList: PropTypes.array,
  width: PropTypes.string,
  onPick: PropTypes.func,
  size: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ServicesUsersPicker;
