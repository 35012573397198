import React, { useState, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Tag, Tooltip, message } from 'antd';
import UserImage from 'src/components/layout/UserImage';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { patchTicketConversation } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import EditItemModal from './ConversationHistoryItemEdit';
import { PiShootingStarDuotone } from 'react-icons/pi';
import { uuid } from 'short-uuid';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const StylesWrapper = styled.div`
  overflow-wrap: anywhere;

  .conv-history-left-image {
    width: 9px;
    height: 9px;
    color: #6466f1;
  }

  .conv-history-right-image {
    width: 9px;
    height: 9px;
    color: #6466f1;
  }

  .conv-history-item-left {
    border-radius: 0 0.8rem 0.8rem 0.8rem;
  }

  .conv-history-item-right {
    border-radius: 0.8rem 0 0.8rem 0.8rem;
  }

  .conv-history-item-posting {
    filter: blur(1px);
    opacity: 0.6;
  }

  .conv-history-item-new {
    opacity: 1;
    animation-name: item-new-animation;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
  }

  .conv-history-item-new-left {
    transform-origin: left;
  }

  .conv-history-item-new-right {
    transform-origin: right;
  }

  @keyframes item-new-animation {
    50% {
      transform: scale(1.2);
    }
  }
`;

const ConversationHistoryItem = ({ side, item }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [busyModal, setBusyModal] = useState(false);
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const { currentTicketConversation, setCurrentTicketConversation } = useContext(TeacketContext);

  const handleEditClick = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = () => {
    setEditModalVisible(false);
  };

  const handleOnSave = (text, visibility) => {
    console.log('handleOnSave', text, visibility);
    executeAsyncProcess(async () => {
      try {
        setBusyModal(true);
        const copy = [...currentTicketConversation];
        const index = copy.findIndex((conv) => conv.id === item.id);
        if (index !== -1) {
          copy[index] = { ...currentTicketConversation[index], isPosting: true };
          setCurrentTicketConversation(copy);
        }

        await patchTicketConversation(item.ticket_id, item.id, { text, visibility });

        messageApi.open({
          key: uuid(),
          type: 'success',
          content: 'Conversation updated',
          duration: 3,
        });
        setEditModalVisible(false);
      } catch (error) {
        console.error(error);
      } finally {
        setBusyModal(false);
      }
    });
  };

  const applyStyleForMsg = (item, side) => {
    let style = '';
    if (item?.props?.is_rejectedMsg) {
      style = 'text-white bg-red-600 dark:border-red-600 opacity-80';
    } else if (item.visibility === 'internal') {
      style = 'text-white bg-gray-500 dark:border-gray-500';
    } else {
      style = 'text-white bg-blue-600 dark:border-blue-700';
    }
    // if (style === '') {
    //   if (side === 'right') style = 'text-white bg-blue-600 dark:border-blue-700';
    //   else style = 'text-black  bg-gray-100 border-gray-200 dark:text-white dark:bg-zinc-800 dark:border-zinc-800';
    // }
    return style;
  };

  return (
    <StylesWrapper>
      {contextHolder}
      {editModalVisible && <EditItemModal title='Ticket summary generated by AI' item={item} isVisible={editModalVisible} onClose={handleModalClose} onSave={handleOnSave} busy={busyModal} />}
      <div className={`m-4 ${item.isPosting ? 'conv-history-item-posting animate-pulse' : item.isNew ? `conv-history-item-new conv-history-item-new-${side}` : null}`}>
        <div className='px-11 pb-1'>
          <div className={`flex gap-2 items-center text-xs ${'right' === side ? 'flex-row-reverse' : 'flex-row'}`}>
            <div className='font-light opacity-80'>
              {dayjs(item?.created_at).fromNow()} by {item?.user_name ?? 'Unknown user'}{' '}
            </div>
            {item?.props?.is_summary &&
              (item.visibility === 'internal' ? (
                <Tooltip title='Click to edit'>
                  <Tag className='bg-teal-600' onClick={handleEditClick}>
                    <PiShootingStarDuotone className='pt-1 scale-125' /> Summary
                  </Tag>
                </Tooltip>
              ) : (
                <Tag className='bg-teal-600 text-left'>
                  <PiShootingStarDuotone className='pt-1 scale-125' /> Summary
                </Tag>
              ))}
            {item.visibility === 'internal' && (
              <Tag color='orange' style={{ margin: 0, fontSize: '0.8rem' }}>
                internal
              </Tag>
            )}
          </div>
        </div>
        <div className={`flex  ${'right' === side ? 'flex-row-reverse' : 'flex-row'} gap-2`}>
          <div className='w-9 text-center'>
            <UserImage image={item?.user_pic} size={'32px'} />
          </div>
          <div
            className={`
              ${applyStyleForMsg(item, side)} 
           py-2 px-4 conv-history-item-${side} max-w-[85%] border border-solid content-center `}
            dangerouslySetInnerHTML={{ __html: item?.text }}
          />
        </div>
      </div>
    </StylesWrapper>
  );
};

ConversationHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  side: PropTypes.string.isRequired,
};

ConversationHistoryItem.defaultProps = {
  side: 'left',
};

export default ConversationHistoryItem;
