import React, { useState, useEffect, useContext } from 'react';
import TeacketContext from 'src/context/TeacketContext';
import { getTicketTimeTrackingTotal } from 'src/api/teacket';
import PropTypes from 'prop-types';
import { FaRegClock } from 'react-icons/fa6';
import Space2 from 'src/components/layout/Space2';
import GlobalWsMessenger from 'src/components/WebSocket/GlobalWsMessenger';

const TicketTimeTrackingTotal = () => {
  const { currentTicket, currentTicketMembers, setCurrentTicketTimeTrackingForceReload, currentTicketTimeTrackingForceReload, getCurrentTicketService, currentTicketTimeTrackingTotal, setCurrentTicketTimeTrackingTotal } = useContext(TeacketContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentTicket?.id) return;
    loadData();
  }, [currentTicket]);

  useEffect(() => {
    if (!currentTicketTimeTrackingForceReload) return;
    loadData();
    setCurrentTicketTimeTrackingForceReload(false);
  }, [currentTicketTimeTrackingForceReload]);

  const noValidMembers = () => {
    if (!currentTicketMembers) {
      return true;
    }
    if (currentTicketMembers.length === 0) {
      return true;
    }

    // If there is only Requester role assigned to the ticket, hide the time tracking
    if (currentTicketMembers.length === 1) {
      const currentTicketService = getCurrentTicketService();
      if (!currentTicketService) return false;
      const { roleList } = currentTicketService?.props ?? {};
      const requesterRole = roleList.find((role) => role.isRequester);
      if (currentTicketMembers[0].role === requesterRole?.role) {
        return true;
      }
    }

    return false;
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const { total: t } = await getTicketTimeTrackingTotal(currentTicket.id);
      setCurrentTicketTimeTrackingTotal(t ?? 0);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleOnWsMessageReceived = (e) => {
    console.log('[TicketTimeTrackingTotal] handleOnWsMessageReceived()', e);
    try {
      if ('TICKET_TIME_TRACKING_TOTAL' !== e.action) return;
      const { total: t } = e?.data ?? {};
      setCurrentTicketTimeTrackingTotal(t ?? 0);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!noValidMembers() && (
        <div className='text-sm'>
          <GlobalWsMessenger onMessageReceived={handleOnWsMessageReceived} actionList={['TICKET_TIME_TRACKING_TOTAL']} />
          <Space2>
            <FaRegClock className={loading ? 'animate-pulse' : null} />
            {currentTicketTimeTrackingTotal || currentTicketTimeTrackingTotal === 0 ? currentTicketTimeTrackingTotal : '...'}
          </Space2>
        </div>
      )}
    </>
  );
};

TicketTimeTrackingTotal.propTypes = { ticketId: PropTypes.string };

export default TicketTimeTrackingTotal;
