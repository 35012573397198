import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAdminServiceTenantProp, putAdminServiceTenantProp } from 'src/api/teacket';
import useAsyncProcesses from 'src/hooks/useAsyncProcesses';
import PermissionPicker from 'src/components/controls/PermissionPicker/PermissionPicker';
import { Spin, message } from 'antd';

const TeacketServiceEditorPermissions = ({ serviceId, tenantId }) => {
  const PROP_NAME = 'requiredPermission';
  const PROP_NAME_SUPERVISOR_FILTERS = 'supervisorFiltersRequiredPermission';
  const { executeAsyncProcess } = useAsyncProcesses();
  const [messageApi, contextHolder] = message.useMessage();

  const [requiredPermission, setRequiredPermission] = useState(null);
  const [supervisorFiltersRequiredPermission, setSupervisorFiltersRequiredPermission] = useState(null);
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await executeAsyncProcess(async () => {
      const resp = await getAdminServiceTenantProp(serviceId, tenantId, PROP_NAME);
      setRequiredPermission(resp?.value ?? null);
      const resp2 = await getAdminServiceTenantProp(serviceId, tenantId, PROP_NAME_SUPERVISOR_FILTERS);
      setSupervisorFiltersRequiredPermission(resp2?.value ?? null);
      setInitiated(true);
    });
  };

  const handleOnPick = async (p) => {
    console.log('handleOnPick:', p);
    await executeAsyncProcess(async () => {
      const resp = await putAdminServiceTenantProp(serviceId, tenantId, PROP_NAME, p?.permission ?? null);
      setRequiredPermission(resp[PROP_NAME]);
      messageApi.open({
        type: 'success',
        content: 'Required permission updated',
        duration: 3,
      });
    });
  };

  const handleOnSupervisorFiltersPick = async (p) => {
    console.log('handleOnSupervisorFiltersPick:', p);
    await executeAsyncProcess(async () => {
      const resp = await putAdminServiceTenantProp(serviceId, tenantId, PROP_NAME_SUPERVISOR_FILTERS, p?.permission ?? null);
      setRequiredPermission(resp[PROP_NAME_SUPERVISOR_FILTERS]);
      messageApi.open({
        type: 'success',
        content: 'Required permission updated',
        duration: 3,
      });
    });
  };

  return (
    <div className='flex flex-col items-start gap-2 m-1 p-4 border border-solid border-sky-300 dark:border-sky-700 rounded-lg'>
      {contextHolder}
      {!initiated ? (
        <Spin />
      ) : (
        <div className='flex flex-col xl:flex-row gap-2'>
          <div>
            <div>
              General Access <span className='font-light opacity-60'>required permission</span>
            </div>
            <div>
              <PermissionPicker width='300px' onPick={handleOnPick} defaultValue={requiredPermission} placeholder='[Not specified]' />
            </div>
          </div>
          <div>
            <div>
              Supervisor Filters <span className='font-light opacity-60'>required permission</span>
            </div>
            <div>
              <PermissionPicker width='300px' onPick={handleOnSupervisorFiltersPick} defaultValue={supervisorFiltersRequiredPermission} placeholder='[Not specified]' />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TeacketServiceEditorPermissions.propTypes = {
  serviceId: PropTypes.string,
  tenantId: PropTypes.string,
};

export default TeacketServiceEditorPermissions;
