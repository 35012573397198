import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import TeacketContext from 'src/context/TeacketContext';
import CondensedTag from 'src/components/layout/CondensedTag';

const TicketTypeTag = ({ type, styling }) => {
  const { ticketTypes } = useContext(TeacketContext);
  const currentType = ticketTypes?.find((x) => x.type === type);

  return (
    currentType && (
      <Tooltip title={currentType.name}>
        <div className={`${styling ? styling : ''}`}>
          <CondensedTag color={currentType.color}>{currentType.name.substring(0, 1).toUpperCase()}</CondensedTag>
        </div>
      </Tooltip>
    )
  );
};

TicketTypeTag.propTypes = {
  type: PropTypes.string.isRequired,
  styling: PropTypes.string,
};

export default TicketTypeTag;
