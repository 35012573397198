import React from 'react';
import { useNavigate } from 'react-router-dom';
import BoxType2 from './BoxType2';
import { FaVideo } from 'react-icons/fa';
import { Space, Button } from 'antd';
import VideoItem from '../../Videos/Components/VideoItem';

//import PropTypes from 'prop-types';

const SectionVideos = () => {
  const navigate = useNavigate();

  const title = (
    <Space className='btn'>
      <FaVideo />
      Videos
    </Space>
  );

  return (
    <BoxType2
      title={title}
      extras2={
        <Button
          onClick={() => {
            navigate('/videos');
          }}>
          <Space className='btn'>
            <FaVideo />
            See all videos
          </Space>
        </Button>
      }>
      <div className='w-full flex flex-row justify-center'>
        <div className='grid grid-cols-1 xl:grid-cols-2 gap-12'>
          <VideoItem videoId='17f97945-aa1b-4aaf-a2de-c3bb7d90bb2a' title='Genesys Cloud Data Privacy Demo' description='Leveraging Genesys Cloud Privacy and Security features to personalize experiences whilst avoiding sensitive data storage. Check also the file called "Genesys Cloud Data Privacy Guide" in the "Privacy" section above.' thumbnail='/images/thumbnails/thumbnail-data-privacy-demo.png' modifiedDate='June 30, 2024, 11:13:43' />
          {/* <VideoItem videoId='27f97945-aa1b-4aaf-a2de-c3bb7d90bb2a' title='Operational Event Notifications & Monitoring' description='How to monitor events and notifications in Genesys Cloud to ensure compliance with data privacy regulations.' thumbnail='/images/thumbnails/thumbnail-operational-event-notifications-and-monitoring.png' modifiedDate='June 28, 2024, 15:49:35' /> */}
          {/* <VideoItem videoId='37f97945-aa1b-4aaf-a2de-c3bb7d90bb2a' title='Operational Status and Incidents' description='How to check the operational status of Genesys Cloud and receive notifications about incidents.' thumbnail='/images/thumbnails/thumbnail-operational-status-and-incidents.png' modifiedDate='June 28, 2024, 15:50:02' /> */}
          <VideoItem videoId='a792faf9-501f-49d6-a3a7-e9fb978f45e8' title='Software Development Lifecycle' description='Overview of the Genesys Cloud Software Development Lifecycle and Release Management Process underpinning the main pillars of security, availability, and feature velocity of Genesys Cloud.' thumbnail='/images/thumbnails/thumbnail-SDL.png' modifiedDate='August 13, 2024, 09:59:17' />
          <VideoItem videoId='b792faf9-501f-49d6-a3a7-e9fb978f45e8' title='Audit Events to Splunk' description='Overview of how Genesys Cloud audits can be forwarded to a Splunk instance. It includes a complete terraform configuration that creates a Genesys Cloud Event Bridge integration, writes events from the integration into a Kinesis stream & S3 for backup purposes.' thumbnail='/images/thumbnails/thumbnail-splunk.png' modifiedDate='June 7, 2024, 15:58:08' />
        </div>
      </div>
    </BoxType2>
  );
};

SectionVideos.propTypes = {};

export default SectionVideos;
