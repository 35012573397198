import React, { useContext } from 'react';
import { Select, Tooltip } from 'antd';
import TeacketContext from 'src/context/TeacketContext';
import styled from 'styled-components';
import useTheme from 'src/hooks/useTheme';
import { AiOutlineGroup } from 'react-icons/ai';
import Space2 from 'src/components/layout/Space2';

const Styled = styled.div`
  .ant-select-selector {
    border-color: ${(props) => props.theme.textBase} !important;
    background-color: transparent !important;
  }
  .ant-select-selection-search {
    color: ${(props) => props.theme.textBase} !important;
  }
  .ant-select-selection-placeholder {
    color: ${(props) => props.theme.textBase} !important;
    opacity: 0.4;
  }
  .ant-select-clear {
    color: ${(props) => props.theme.primary} !important;
  }
`;

const TicketList2Grouping = () => {
  const { theme } = useTheme();
  const { ticketListGroupBy, setTicketListGroupBy } = useContext(TeacketContext);
  return (
    <Styled theme={theme}>
      <Tooltip title='Data grouping'>
        <Select
          size='small'
          placeholder={<div className='text-center w-full'>[Not grouped]</div>}
          style={{ width: 140 }}
          onChange={(value) => setTicketListGroupBy(value)}
          value={ticketListGroupBy}
          allowClear
          options={[
            { label: 'Account', value: 'acc_id' },
            { label: 'Category', value: 'category' },
            { label: 'Status', value: 'status' },
          ]}
          labelRender={(item) => (
            <Space2 className='font-bold'>
              <AiOutlineGroup />
              {item.label}
            </Space2>
          )}
        />
      </Tooltip>
    </Styled>
  );
};

export default TicketList2Grouping;
