import React, { useState } from 'react';
import RegionPicker from 'src/components/controls/RegionPicker/RegionPicker';
import TicketTypePicker from './TicketTypePicker';
import PropTypes from 'prop-types';
import PlatformPicker from './PlatformPicker';
import CategoryPicker from './CategoryPicker';
import SubCategoryPicker from './SubCategoryPicker';
import useTheme from 'src/hooks/useTheme';
import StyledButton from 'src/components/layout/StyledButton';
import Space2 from 'src/components/layout/Space2';
import { FaPlus } from 'react-icons/fa';
import { SiTestrail } from 'react-icons/si';
import { Tooltip } from 'antd';

const TeacketServiceEditorRoutingEditor = ({ serviceId, onAdd, onTest }) => {
  const { theme } = useTheme();

  const [ticketType, setTicketType] = useState(null);
  const [region, setRegion] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  const handleTicketTypePickerOnChange = (value) => {
    console.log('handleTicketTypePickerOnChange()', value);
    setTicketType(value);
  };

  const handleRegionPickerOnChange = (value) => {
    console.log('handleRegionPickerOnChange()', value);
    setRegion(value);
  };

  const handlePlatformPickerOnChange = (value) => {
    console.log('handlePlatformPickerOnChange()', value);
    setPlatform(value);
  };

  const handleCtaegoryPickerOnChange = (value) => {
    console.log('handleCtaegoryPickerOnChange()', value);
    setCategory(value);
    // setSubCategoryOptions(value?.items ?? null);
  };

  const handleSubCategoryPickerOnChange = (value) => {
    console.log('handleSubCategoryPickerOnChange()', value);
    setSubCategory(value);
  };

  const handleAddRuleOnClick = () => {
    console.log('handleAddRuleOnClick()');
    if (!onAdd) return;
    onAdd({ ticket_type: ticketType, region: region?.key ?? null, product: platform, category: category?.category ?? null, sub_category: subCategory });
  };

  const handleTestOnClick = () => {
    console.log('handleTestOnClick()');
    if (!onTest) return;
    onTest({ ticket_type: ticketType, region: region?.key ?? null, product: platform, category: category?.category ?? null, sub_category: subCategory });
  };

  const canTest = () => {
    if (!ticketType) return false;
    if (region && !ticketType) return false;
    if (platform && !region) return false;
    if (category && !platform) return false;
    if (subCategory && !category) return false;
    return true;
  };

  return (
    <div className='flex flex-col gap-2'>
      <div>
        <div>Ticket Type:</div>
        <TicketTypePicker serviceIds={[serviceId]} onChange={handleTicketTypePickerOnChange} />
      </div>
      <div>
        <div>Region:</div>
        <RegionPicker placeholder='[ALL]' width='100%' onChange={handleRegionPickerOnChange} allowClear />
      </div>
      <div>
        <div>Product:</div>
        <PlatformPicker onChange={handlePlatformPickerOnChange} />
      </div>
      <div>
        <div>Category:</div>
        <CategoryPicker serviceId={serviceId} onChange={handleCtaegoryPickerOnChange} />
      </div>
      <div>
        <div>SubCategory:</div>
        <SubCategoryPicker options={category?.subCategoryList} onChange={handleSubCategoryPickerOnChange} />
      </div>
      <div className='my-2 flex flex-col gap-2'>
        <Tooltip title={false === canTest() ? "Can't test rules with selected criterias" : null} placement='left'>
          <StyledButton className='w-full' color={theme.textBase} onClick={handleTestOnClick} disabled={false === canTest()}>
            <Space2>
              <SiTestrail />
              Test
            </Space2>
          </StyledButton>
        </Tooltip>

        <StyledButton className='w-full' color={theme.textBase} onClick={handleAddRuleOnClick}>
          <Space2>
            <FaPlus />
            Add a rule
          </Space2>
        </StyledButton>
      </div>
    </div>
  );
};

TeacketServiceEditorRoutingEditor.propTypes = {
  serviceId: PropTypes.string,
  onAdd: PropTypes.func,
  onTest: PropTypes.func,
};

export default TeacketServiceEditorRoutingEditor;
