import { useContext, useEffect } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import PropTypes from 'prop-types';

const FuncKeys = ({ children }) => {
  const { setShiftPressed, setCommandPressed } = useContext(GlobalContext);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      setCommandPressed(false);
      setShiftPressed(false);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleKeyDown = (e) => {
    // console.log('handleKeyDown', e.key);
    switch (e.key) {
      case 'Meta':
        setCommandPressed(true);
        break;
      case 'Shift':
        setShiftPressed(true);
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (e) => {
    //console.log('handleKeyUp', e.key);
    switch (e.key) {
      case 'Meta':
        setCommandPressed(false);
        break;
      case 'Shift':
        setShiftPressed(false);
        break;
      default:
        break;
    }
  };

  return children;
};

FuncKeys.propTypes = {
  children: PropTypes.node,
};

export default FuncKeys;
