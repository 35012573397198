import React from 'react';
import PropTypes from 'prop-types';
import { Input, Space } from 'antd';
import { notEmptyArray, textSorterStrArray } from 'src/misc/Misc';

const FieldString = ({ propName, fieldDef, defaultValue, onChange }) => {
  //console.log('defaultValue', propName, defaultValue);
  return (
    <div>
      <Space>
        {fieldDef.description}
        {fieldDef?.details && <span className='text-xs opacity-60 ml-2'>{fieldDef?.details}</span>}
      </Space>
      <div>
        <Input
          defaultValue={defaultValue}
          onChange={(e) => {
            if (!onChange) return;
            onChange({ propName, value: e.target.value });
          }}
          placeholder='Enter a value...'
        />
      </div>
      {true === notEmptyArray(fieldDef.enum) && (
        <div className='text-xs'>
          <div className='font-light opacity-40'>Allowed values for '{fieldDef.description}':</div>
          <ul className='m-0 font-bold opacity-60'>
            {fieldDef.enum
              .sort((a, b) => textSorterStrArray(a, b))
              .map((v) => (
                <li key={v}>{v}</li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

FieldString.propTypes = {
  propName: PropTypes.string,
  fieldDef: PropTypes.object,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default FieldString;
