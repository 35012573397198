import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Tooltip } from 'antd';
import { FaEdit } from 'react-icons/fa';
import useTheme from 'src/hooks/useTheme';
import { invertColor } from 'src/misc/Misc';

const PropertyItem = ({ label, labelExtras, value, secondValue, loading, onClick, isHighlighted, propertyColor, isValueLink = false }) => {
  const { theme, isDarkMode } = useTheme();

  const getPropertyColor = () => {
    if (!propertyColor) return null;
    if (isDarkMode) return invertColor(propertyColor);
    return propertyColor;
  };

  const getPropertyBorder = () => {
    if (isHighlighted) return '2px dashed #EF4444';
    if (propertyColor) return `1px solid ${isDarkMode ? invertColor(propertyColor) : propertyColor}`;
    return `1px solid ${theme.borderLight}`;
  };

  const getValue = () => {
    if (isValueLink) {
      return (
        <a href={value && value.startsWith('http') ? value : `https://${value}`} target='_blank' rel='noreferrer'>
          {value}
        </a>
      );
    }
    return value;
  };

  return (
    // <div className={`${isHighlighted ? 'border-2 border-dashed border-red-500 dark:border-red-500' : 'border border-solid border-gray-200 dark:border-gray-800'} rounded-xl flex flex-row overflow-hidden`}>
    <div className={'min-h-[28px] mt-1 relative rounded-lg flex flex-row'} style={{ border: getPropertyBorder() }}>
      <div className='absolute top-[-9px] left-[8px] z-10 flex flex-row flex-wrap gap-2 text-xs px-1' style={{ backgroundColor: theme.backgroundBase }}>
        <div className={'font-semibold opacity-80'} style={{ color: getPropertyColor() }}>
          {label ?? 'Unknown property'}
        </div>
        {labelExtras && <div>{labelExtras}</div>}
      </div>
      <div className='relative flex-auto py-1 px-3 overflow-hidden'>
        {loading ? (
          <div className='text-right block'>
            <Skeleton.Input active={true} size={24} />
          </div>
        ) : (
          <div className='flex flex-col'>
            <div className='text-right text-ellipsis text-sm' style={{ wordBreak: 'break-word' }}>
              {getValue() ?? 'N/A'}
            </div>
            {secondValue && (
              <div className='text-right font-light text-ellipsis text-xs opacity-60' style={{ wordBreak: 'break-word' }}>
                {secondValue}
              </div>
            )}
          </div>
        )}
      </div>
      {onClick && (
        <Tooltip title='Click to edit'>
          <div className={'w-[2.4rem] min-w-[2.4rem] rounded-r-lg flex flex-row justify-center items-center hover:opacity-90 cursor-pointer'} style={{ color: theme.primary, borderLeft: `1px solid ${theme.borderLight}` }} onClick={onClick}>
            <FaEdit className='text-lg' />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

PropertyItem.propTypes = {
  label: PropTypes.any,
  labelExtras: PropTypes.any,
  value: PropTypes.any,
  secondValue: PropTypes.any,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  isHighlighted: PropTypes.bool,
  propertyColor: PropTypes.string,
  isValueLink: PropTypes.bool,
};

export default PropertyItem;
