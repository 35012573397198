import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PropertySection from 'src/components/layout/PropertySection';
import ServiceUserPicker from '../../Components/ServiceUserPicker';
import { Alert, Input, Select } from 'antd';
import useDictionary from 'src/hooks/useDictionary';
import { notArrayOrEmpty } from 'src/misc/Misc';
import { determineRegion } from '../../Misc/misc';

const TicketCreateLegalSupport = ({ serviceId, onChange, oppRegion, oppAcvUsd, oppSegment }) => {
  const regionDictionary = useDictionary('teacket-region');
  const regionConfig = {
    EMEA: {
      threshold: {
        enterprise: 700000,
        default: 700000,
      },
    },
    APAC: {
      threshold: {
        enterprise: 500000,
        default: 500000,
      },
    },
    LATAM: {
      threshold: {
        enterprise: 500000,
        default: 500000,
      },
    },
    NA: {
      threshold: {
        enterprise: 2000000,
        default: 750000,
      },
    },
  };

  const termsOptions = [
    { value: 'Standard Terms', label: 'Standard Terms' },
    { value: 'Negociation on Genesys Paper', label: 'Negociation on Genesys Paper' },
    { value: 'Negociation on Customer Paper', label: 'Negociation on Customer Paper' },
    { value: 'No Contract Terms', label: 'No Contract Terms' },
  ];

  const [legalOwner, setLegalOwner] = useState(null);
  const [clmTicketUrl, setClmTicketUrl] = useState(null);
  const [terms, setTerms] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const [oppRegionDict, setOppRegionDict] = useState(null);

  useEffect(() => {
    if (!onChange) return;
    onChange({ legalOwner, clmTicketUrl, terms });
  }, [legalOwner, clmTicketUrl, terms]);

  useEffect(() => {
    if (true === notArrayOrEmpty(regionDictionary)) return;
    if (!oppRegion) return;
    const region = determineRegion(regionDictionary, oppRegion);
    setOppRegionDict(region);
  }, [regionDictionary, oppRegion]);

  useEffect(() => {
    determineIfShowBanner();
  }, [oppRegionDict, oppRegion, oppAcvUsd, oppSegment]);

  const determineIfShowBanner = () => {
    console.log('determineIfShowBanner', oppRegionDict, oppAcvUsd, oppSegment);
    if (!oppRegionDict) return false;
    if (!oppAcvUsd) return false;
    if (!oppSegment) return false;
    const config = regionConfig[oppRegionDict];
    if (!config) {
      setShowBanner(false);
      return;
    }
    const threshold = config.threshold[oppSegment?.toLowerCase()?.startsWith('enterprise') ? 'enterprise' : 'default'];
    if (!threshold) {
      setShowBanner(false);
      return;
    }
    // console.log('threshold', oppRegionDict, threshold, oppAcvUsd, (oppAcvUsd ?? 0) < (threshold ?? 0));
    setShowBanner((oppAcvUsd ?? 0) < (threshold ?? 0));
  };

  if (!regionDictionary) return null;

  return (
    <div className='mb-4'>
      <PropertySection>
        <div className='my-2 flex flex-col gap-4'>
          {true === showBanner && <Alert message='This opportunity may not meet our minimum Annual Contract Value (ACV) threshold for high-touch legal support engagements. We typically prioritize opportunities above specific thresholds to ensure optimal resource allocation.' type='warning' showIcon />}
          <div>
            <div>Legal Owner</div>
            <ServiceUserPicker serviceId={serviceId} size={null} onSelect={(e) => setLegalOwner(e)} />
          </div>
          <div>
            <div>CLM Ticket URL</div>
            <Input onChange={(e) => setClmTicketUrl(e.target.value)} />
          </div>
          <div>
            <div>Terms</div>
            <Select options={termsOptions} style={{ width: '100%' }} onChange={(e) => setTerms(e)} />
          </div>
        </div>
      </PropertySection>
    </div>
  );
};

TicketCreateLegalSupport.propTypes = {
  serviceId: PropTypes.string,
  onChange: PropTypes.func,
  oppRegion: PropTypes.string,
  oppAcvUsd: PropTypes.number,
  oppSegment: PropTypes.string,
};

export default TicketCreateLegalSupport;
