import { handleResponse, getAccessHeaders } from './common';
import { ENV } from 'src/misc/Config';
import { objToQueryStringParams } from 'src/misc/Misc';

var controllerSearchUsers;

//#region Users

export const searchUsers = async (query, page = 1, pageSize = 24) => {
  controllerSearchUsers?.abort('cancelled');
  controllerSearchUsers = new AbortController();
  const signal = controllerSearchUsers.signal;
  const queryStringParams = objToQueryStringParams({ query, page, pageSize });
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/search${queryStringParams}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
      signal,
    })
  );
};

export const getUser = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const patchUser = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const postUserCognito = async (data) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/cognito`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const postUsersCognitoResetPassword = async (email, password) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/cognito/reset-password`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    })
  );
};

export const deleteUsersCognito = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/cognito/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const searchMultipleUsers = async (uniqueUsers) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/search-multiple?source=gc&fields=name,id,image`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(uniqueUsers),
    })
  );
};

//#endregion

//#region Departments

export const getDepartments = async () => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/departments`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region Groups

export const getGroups = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/groups`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const getGroup = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/groups/${id}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const createGroup = async (data) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/groups`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  );
};

export const patchGroup = async (id, changes) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/groups/${id}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(changes),
    })
  );
};

export const deleteGroup = async (id) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/groups/${id}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion

//#region User Filters

export const getUserFilters = async (dashboardId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/filters?dashboardId=${dashboardId}`, {
      method: 'GET',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

export const postUserFilter = async (dashboardId, filter) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/filters?dashboardId=${dashboardId}`, {
      method: 'POST',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(filter),
    })
  );
};

export const patchUserFilter = async (dashboardId, filterId, filter) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/filters?dashboardId=${dashboardId}&filterId=${filterId}`, {
      method: 'PATCH',
      headers: { ...headers, 'Content-Type': 'application/json' },
      body: JSON.stringify(filter),
    })
  );
};

export const deleteUserFilter = async (dashboardId, filterId) => {
  const headers = await getAccessHeaders();
  return handleResponse(
    await fetch(`${ENV.apiUrl}/users/filters?dashboardId=${dashboardId}&filterId=${filterId}`, {
      method: 'DELETE',
      headers: { ...headers, 'Content-Type': 'application/json' },
    })
  );
};

//#endregion
